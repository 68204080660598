import { createStore } from "vuex";

// modules
import storeProps from "./modules/storeProps/storeProps";
import products from "./modules/products/products";
import product from "./modules/product/product";

const store = createStore({
    modules: {
        storeProps,
        products,
        product,
    },
});

export default store;
