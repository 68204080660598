import { ref } from "vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

export default {
    name: "ProductCarousel",
    components: {
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        slides: [],
    }),
    props: {
        items: {
            type: Array,
            defaultValue: [],
        },
        selected_combination: {
            type: String,
            defaultValue: "",
        },
    },
    setup() {
        const previewSlider = ref(null);
        const thumbsSwiper = ref(null);

        const setThumbsSwiper = swiper => {
            thumbsSwiper.value = swiper;
        };

        const setPreviewCarousel = swiper => {
            previewSlider.value = swiper;
        };

        return {
            thumbsSwiper,
            previewSlider,
            setThumbsSwiper,
            setPreviewCarousel,
            modules: [FreeMode, Navigation, Thumbs],
        };
    },
    methods: {
        onCurrentSlideChange({ realIndex }) {
            this.$emit("onSlideChange", this.items[realIndex][0]);
        },
        goToSlide() {
            const new_active_item_index = this.items.findIndex(([code_combination]) => code_combination === this.selected_combination);
            this.previewSlider.slideTo(new_active_item_index);
            this.thumbsSwiper.slideTo(new_active_item_index);
        },
        handleItems() {
            this.slides = this.items.map(([, { store_product_image_url, product_name }]) => ({ img: store_product_image_url, text: product_name }));
        },
    },
    emits: {
        onSlideChange: null,
    },
    watch: {
        items() {
            this.handleItems();
        },
        selected_combination() {
            this.goToSlide();
        },
    },
    updated() {
        this.goToSlide();
    },
    mounted() {
        this.handleItems();
    },
};
