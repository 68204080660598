import CustomButton from "@/components/ui/customButton/customButton.vue";
import Icon from "@/components/ui/icon/icon.vue";

export default {
    name: "EmptyState",
    components: { Icon, CustomButton },
    props: {
        icon: {
            type: String,
            default: "fileIcon",
        },
        text: {
            type: String,
            default: "No Results...",
        },
        is_button: {
            type: Boolean,
            default: true,
        },
        text_button: {
            type: String,
            default: "Back to Shop",
        },
    },
    emits: ["onClick"],
    methods: {
        handleClick() {
            this.$emit("onClick");
        },
    },
};
