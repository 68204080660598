import { getStoreProps } from "@/services/storeProps.service";

const state = {
    themeType: "initial-type",
    themeMode: "light",
    themeStyles: {},
    logoURL: "",
    bannerURL: "",
    aboutImageURL: "",
    aboutText: "",
    title: "",
    storeName: "",
    storeSettings: {
        currency: {
            id: 1,
            is_active: 1,
            is_has_products: 1,
            is_has_stores: 1,
            rate: 1,
            symbol: "£",
        },
        store_name: "",
    },
    product_categories: [],
    socialLinks: {},
    isLoading: true,
    previewMode: false,
};

const getters = {
    getThemeType(state) {
        return state.themeType;
    },
    getThemeMode(state) {
        return state.themeMode;
    },
    getThemeStyles(state) {
        return state.themeStyles;
    },
    getStoreSettings(state) {
        return state.storeSettings;
    },
    getLogoURL(state) {
        return state.logoURL;
    },
    getAboutImageURL(state) {
        return state.aboutImageURL;
    },
    getIsLoading(state) {
        return state.isLoading;
    },
    getBannerURL(state) {
        return state.bannerURL;
    },
    getStoreName(state) {
        return state.storeName;
    },
    getAboutText(state) {
        return state.aboutText;
    },
    getTitle(state) {
        return state.title;
    },
    getPreviewMode(state) {
        return state.previewMode;
    },
    getStoreCategories(state) {
        return state.product_categories;
    },
    getSocialLinks(state) {
        return state.socialLinks;
    },
};

const mutations = {
    setThemeType(state, payload) {
        state.themeType = payload;
    },
    setThemeMode(state, payload) {
        state.themeMode = payload;
    },
    setThemeStyles(state, payload) {
        state.themeStyles = payload;
    },
    setStoreSettings(state, payload) {
        state.storeSettings = payload;
    },
    setLogoURL(state, payload) {
        state.logoURL = payload;
    },
    setBannerURL(state, payload) {
        state.bannerURL = payload;
    },
    setAboutImageURL(state, payload) {
        state.aboutImageURL = payload;
    },
    setIsLoading(state, payload) {
        state.isLoading = payload;
    },
    setStoreName(state, payload) {
        state.storeName = payload;
    },
    setAboutText(state, payload) {
        state.aboutText = payload;
    },
    setTitle(state, payload) {
        state.title = payload;
    },
    setPreviewMode(state, payload) {
        state.previewMode = payload;
    },
    setStoreCategories(state, payload) {
        state.product_categories = payload;
    },
    setSocialLinks(state, payload) {
        state.socialLinks = payload;
    },
    setEmail(state, payload) {
        state.storeSettings.email = payload;
    },
};

const actions = {
    async getStoreProducts(context) {
        const {
            data: { store, product_categories },
        } = await getStoreProps();
        const { meta_data, ...rest } = store;

        const { themeType, themeMode, styles, aboutURL, bannerURL, socialLinks, ...rest_meta_data } = JSON.parse(meta_data);
        const rest_props = { ...rest, ...rest_meta_data };

        context.commit("setThemeType", themeType);
        context.commit("setThemeMode", themeMode);
        context.commit("setThemeStyles", styles || {});
        context.commit("setStoreSettings", rest_props);
        context.commit("setStoreCategories", product_categories);
        context.commit("setSocialLinks", socialLinks);

        //console.log("{ ...rest, ...rest_meta_data }", rest_props);

        if (rest_props.logoURL || rest_props.store_logo_image) {
            context.commit("setLogoURL", rest_props.logoURL || rest_props.store_logo_image);
        }
        if (aboutURL) {
            context.commit("setAboutImageURL", aboutURL);
        }
        if (bannerURL) {
            context.commit("setBannerURL", bannerURL);
        }
        if (rest_props.store_name || rest_props.storeName) {
            context.commit("setStoreName", rest_props.store_name || rest_props.storeName);
        }
        if (rest_props.title || rest_props.titleText) {
            context.commit("setTitle", rest_props.title || rest_props.titleText);
        }
        if (rest_props.aboutText || rest_props.about) {
            context.commit("setAboutText", rest_props.aboutText || rest_props.about);
        }
    },
    setProps(context, { themeMode, themeType, logoURL, bannerURL, storeName, aboutText, title, aboutURL, previewMode, socialLinks, email }) {
        if (themeMode) {
            context.commit("setThemeMode", themeMode);
        }
        if (themeType) {
            context.commit("setThemeType", themeType);
        }
        if (logoURL) {
            context.commit("setLogoURL", logoURL);
        }
        if (bannerURL) {
            context.commit("setBannerURL", bannerURL);
        }
        if (storeName) {
            context.commit("setStoreName", storeName);
        }
        if (aboutText) {
            context.commit("setAboutText", aboutText);
        }
        if (title) {
            context.commit("setTitle", title);
        }
        if (aboutURL) {
            context.commit("setAboutImageURL", aboutURL);
        }
        if (previewMode) {
            context.commit("setPreviewMode", previewMode);
        }
        if (socialLinks) {
            context.commit("setSocialLinks", socialLinks);
        }
        if (email) {
            context.commit("setEmail", email);
        }
    },
    setIsLoadingState(context, value) {
        context.commit("setIsLoading", value);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
