<template>
    <div class="empty">
        <div class="empty__icon">
            <Icon :name="icon" />
        </div>
        <div class="empty__text">
            {{ text }}
        </div>
        <div class="empty__btn" v-if="is_button">
            <CustomButton :text="text_button" @onClick="handleClick" />
        </div>
    </div>
</template>

<script src="./emptyState.js"></script>
<style src="./emptyState.scss" lang="scss"></style>
