import CountPicker from "@/components/shared/countPicker/countPicker.vue";
import parseCost from "@/helpers/parseCost.js";
import round from "@/helpers/round_number.js";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "ProductCardHorizontal",
    components: {
        CountPicker,
    },
    props: {
        item: {
            type: Object,
            defaultValue: {},
        },
        unavailable: {
            type: Boolean,
            defaultValue: false,
        },
    },
    data: () => {
        return {
            count: 1,
            options: "",
            customization: {
                colour: null,
                font: null,
                text: null,
                type: null,
            },
        };
    },
    methods: {
        ...mapActions("products", ["removeProductFromCart"]),
        ...mapActions("products", ["updateProductInCart"]),

        onCountChange(newCount) {
            this.updateProductInCart({ count: newCount, product: this.item.product });
        },
        deleteItem() {
            this.removeProductFromCart(this.item.product);
        },
        getOptions() {
            this.options = this.item.product.meta_data && this.item.product.meta_data.options ? Object.values(this.item.product.meta_data.options).join(" , ") : "";
        },
        getCustomization() {
            if (this.item.product.is_has_editable_text_area && this.item.product.meta_data && this.item.product.meta_data.customisation) {
                for (const [key, value] of Object.entries(this.item.product.meta_data.customisation)) {
                    this.customization[key] = value;
                }
            }
        },
        goToProduct(product) {
            if (product.meta_data && product.meta_data.customisation && product.meta_data.customisation.text) {
                this.$router.push(
                    "/product/" +
                        product.part_url +
                        `?combination=${product.combination}&text=${product.meta_data.customisation.text}&font=${
                            product.meta_data.customisation.font
                        }&color=${product.meta_data.customisation.colour.substring(1)}&personalise=true`,
                );
            } else if (product.personalise_state !== null) {
                this.$router.push("/product/" + product.part_url + `?combination=${product.combination}&personalise=${product.personalise_state}`);
            } else {
                this.$router.push("/product/" + product.part_url + `?combination=${product.combination}`);
            }
        },
        parseCost,
        round,
    },
    mounted() {
        this.getOptions();
        this.getCustomization();
    },
    computed: {
        ...mapGetters("storeProps", { store_settings: "getStoreSettings" }),
    },
};
