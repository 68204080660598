<template
    ><div class="color-picker">
        <div class="color-picker__heading" v-if="heading">{{ heading }}</div>
        <div class="color-picker__list">
            <div
                class="color-picker__button color-picker__button--selector"
                v-if="showColorSelector"
            >
                <input type="color" @input="handleInputColor" @change="handleChangedColor">
            </div>
            <button
                class="color-picker__button"
                :class="{ 'color-picker__button--selected': selected === color, 'color-picker__button--selected-active': selected === '#ffffff' }"
                v-for="color in colors"
                :key="color"
                :style="{ '--color': color }"
                @click="handleClick(color)"
            >
                <span class="color-picker__button-icon">
                    <icon name="check" />
                </span>
            </button>
        </div>
    </div>
</template>
<script src="./colorPicker.js"></script>
<style src="./colorPicker.scss" lang="scss" scoped />
