import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/index.js";
import store from "@/store";
import Notifications from "@kyvg/vue3-notification";

/*const options = {
    pk: process.env.VUE_APP_STRIPE_KEY,
    testMode: true,
};*/

const app = createApp(App);

app.use(router);
app.use(Notifications);
app.use(store);
app.mount("#app");
