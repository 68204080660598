export default {
    name: "Preloader",
    props: {
        show: {
            type: Boolean,
            defaultValue: false,
        },

        is_global: {
            type: Boolean,
            defaultValue: false,
        },

        is_static: {
            type: Boolean,
            defaultValue: false,
        },
    },
};
