<template>
    <header class="header">
        <div class="header__holder container">
            <router-link class="header__logo" to="/shop">
                <div class="txt" v-if="!logoURL">{{ store_name || "Logo" }}</div>
                <img v-else :src="logoURL || store_settings.store_logo_image" :alt="store_settings.store_name" />
            </router-link>
            <nav class="header__nav" :class="{ active: is_nav_shown }">
                <div class="header__nav-list">
                    <div
                        class="header__nav-item"
                        :class="{ 'header__nav-item--has-indent': category.sub_categories.length, 'header__nav-item--active': category.id === active_item_id }"
                        v-for="category in categories"
                        :key="category.id"
                    >
                        <span v-if="category.sub_categories.length" class="header__nav-link" @click="onNavItemClick(category.id)">{{ category.name }}</span>
                        <router-link v-else class="header__nav-link" :class="{ 'header__nav-link--active': $route.path === category.path }" :to="category.path" @click="onOverlayClick">{{
                            category.name
                        }}</router-link>
                        <div v-if="category.sub_categories.length" class="header__drop">
                            <div class="header__drop-holder container">
                                <div class="header__drop-area">
                                    <div class="header__drop-item" v-for="sub_category in category.sub_categories" :key="sub_category.id">
                                        <button class="header__drop-btn" @click="filterByCategory(sub_category.id)">{{ sub_category.name }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header__nav-holder">
                    <div class="header__search">
                        <div class="header__search-holder" :class="{ show: is_search_opened, 'has-error': search_field_is_empty }">
                            <input
                                class="header__search-input"
                                type="search"
                                :maxlength="limit"
                                placeholder="Search..."
                                enterkeyhint="Search"
                                @keyup.enter="search"
                                v-model="search_value"
                                @input="search_field_is_empty = false"
                            />
                            <button class="header__search-btn" @click="search">
                                <Icon name="searchIcon" />
                            </button>
                        </div>
                        <button class="header__search-opener" @click="onSearchOpenerClick">
                            <Icon name="searchIcon" />
                        </button>
                    </div>
                    <div class="header__actions">
                        <router-link class="header__actions-btn" to="/favorite">
                            <Icon class="header__actions-btn-icon" name="heartIcon" />
                        </router-link>
                        <router-link class="header__actions-btn" to="/account">
                            <Icon class="header__actions-btn-icon" name="userIcon" />
                        </router-link>
                        <router-link class="header__actions-btn" to="/cart">
                            <span class="header__products-count">{{ available_products_count < 10 ? available_products_count : "9+" }}</span>
                            <Icon class="header__actions-btn-icon" name="basket" />
                        </router-link>
                        <button class="header__nav-opener" @click="onMenuOpenerClick">
                            <em class="header__nav-opener-line" />
                        </button>
                    </div>
                </div>
                <span class="header__overlay" @click="onOverlayClick" />
            </nav>
        </div>
    </header>
</template>

<script src="./strideHeader.js" />
<style lang="scss" scoped src="./strideHeader.scss" />
