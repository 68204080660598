<template>
    <div class="venture-product-card" :class="{ 'venture-product-card--not-active': is_not_active }">
        <RouterLink v-if="!is_not_active" :to="'/product/' + part_url" class="venture-product-card__link" @click="onLinkClick" />
        <div class="venture-product-card__img">
            <img :src="store_product_image_path" :alt="product_name" />
        </div>
        <div class="venture-product-card__description">
            <div class="venture-product-card__description-top">
                <button class="venture-product-card__favorite" :class="{ 'venture-product-card__favorite--full': is_in_favorite }" @click="handleFavoriteClick">
                    <Icon :name="is_in_favorite ? 'iconFavoriteFull' : 'iconFavorite'" />
                </button>
                <span class="venture-product-card__label" v-if="label">{{ label }}</span>
                <button class="venture-product-card__add" :class="{ 'venture-product-card__add--disabled': is_disabled }" @click="handleAddToCart" v-if="!is_not_active">
                    <Icon name="plus" />
                </button>
            </div>
            <div class="venture-product-card__description-holder">
                <span class="venture-product-card__title">{{ product_name }}</span>
                <span class="venture-product-card__description-text">{{ description }}</span>
            </div>
            <span class="venture-product-card__cost">{{ currency?.symbol }}{{ parseCost(selling_price) }}</span>
        </div>
    </div>
</template>
<script src="./ventureProductCard.js"></script>
<style src="./ventureProductCard.scss" lang="scss"></style>
