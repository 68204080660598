import StrideHeader from "./variants/stride/strideHeader.vue";
import VentureHeader from "./variants/venture/ventureHeader.vue";
import headerMixin from "./header.mixin.js";
export default {
    name: "Header",
    mixins: [headerMixin],
    components: {
        StrideHeader,
        VentureHeader,
    },
    props: {
        theme: {
            type: String,
            default: "",
        },
    },
};
