import VentureButton from "./variants/ventureButton/ventureButton.vue";
import StrideButton from "./variants/strideButton/strideButton.vue";
import { mapGetters } from "vuex";
import customButtonMixin from "./customButton.mixin.js";
export default {
    name: "CustomButton",
    components: { VentureButton, StrideButton },
    mixins: [customButtonMixin],
    computed: {
        ...mapGetters("storeProps", { themeType: "getThemeType" }),
    },
};
