export default {
    name: "AutoLoader",
    props: {
        paginationData: {
            type: Object,
            required: true,
        },
        isLoading: {},
    },
    emits: {
        onNextBatch: null,
    },
    methods: {
        handleNextBatch() {
            this.$emit("onNextBatch", this.paginationData.page + 1);
        },
        isElementInViewport() {
            const masonry = document.getElementById("auto_loader");
            const observer = new window.IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        if (!this.isLoading) {
                            this.handleNextBatch();
                        }
                        return;
                    }
                },
                {
                    root: null,
                    threshold: 0.2, // set offset 0.1 means trigger if atleast 10% of element in viewport
                },
            );

            if (masonry) {
                observer.observe(masonry);
            }
        },
    },
    mounted() {
        this.isElementInViewport();
    },
};
