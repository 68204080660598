import { getProductData } from "@/services/products.service";

const state = {
    store_product: {
        product_name: "",
        selling_price: 0,
        description: "",
    },
    combinations: {},
    product_categories: [],
    option_values: {},
    has_error: false,
    error_text: "",
};

const getters = {
    getStoreProductData(state) {
        return state.product;
    },
    getProductCategories(state) {
        return state.product_categories;
    },
    getCombinations(state) {
        return state.combinations;
    },
    getOptionValues(state) {
        return state.option_values;
    },
    getHasError(state) {
        return state.has_error;
    },
    getErrorText(state) {
        return state.error_text;
    },
};

const mutations = {
    setStoreProductData(state, payload) {
        state.product = payload;
    },
    setCombinations(state, payload) {
        state.combinations = payload;
    },
    setProductCategories(state, payload) {
        state.product_categories = payload;
    },
    setOptionValues(state, payload) {
        state.option_values = payload;
    },
    setHasError(state, payload) {
        state.has_error = payload;
    },
    setErrorText(state, payload) {
        state.error_text = payload;
    },
};

const actions = {
    async fetchProductData(context, { part_url }) {
        const res = await getProductData({ part_url });
        if (res.statusCode !== 404) {
            const {
                data: { store_product, combinations, product_categories, option_values },
            } = res;
            context.commit("setStoreProductData", store_product);
            context.commit("setCombinations", combinations);
            context.commit("setProductCategories", product_categories);
            context.commit("setOptionValues", option_values);
        } else {
            context.commit("setHasError", true);
            context.commit("setErrorText", res.error.description);
        }
    },
    setHasError(context, value) {
        context.commit("setHasError", value);
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};
