export default {
    name: "sizePicker",
    props: {
        sizes: {
            type: Array,
            defaultValue: [],
        },
        heading: {
            type: String,
            defaultValue: "",
        },
        selected: {
            type: String,
            defaultValue: "",
        },
    },
    emits: {
        onClick: null,
    },
    methods: {
        handleClick(size) {
            this.$emit("onClick", size);
        },
    },
};
