<template>
    <div class="product-carousel">
        <swiper
            :style="{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
            }"
            :spaceBetween="10"
            :navigation="true"
            :thumbs="{ swiper: thumbsSwiper }"
            :modules="modules"
            @slide-change="onCurrentSlideChange"
            @swiper="setPreviewCarousel"
            class="product-carousel__top mySwiper2"
        >
            <swiper-slide v-for="(item, index) in slides" :key="index">
                <div class="image-container-personalise">
                    <img :src="item.img" :alt="item.text" />
                </div>
            </swiper-slide>
        </swiper>
        <swiper
            :style="{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
            }"
            @swiper="setThumbsSwiper"
            :spaceBetween="10"
            :slidesPerView="4"
            :freeMode="true"
            :watchSlidesProgress="true"
            :navigation="true"
            :modules="modules"
            class="product-carousel__thumb mySwiper"
        >
            <swiper-slide v-for="(item, index) in slides" :key="index">
                <div class="product-carousel__thumb-img">
                    <img :src="item.img" :alt="item.text" />
                </div>
                <span class="product-carousel__thumb-text">{{ item.text }}</span>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script src="./productCarousel.js"></script>
<style src="./productCarousel.scss" lang="scss" scoped />
<style lang="scss">
.swiper-button-prev,
.swiper-button-next {
    filter: invert(1);
}
</style>
