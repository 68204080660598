<template>
    <div class="carousel">
        <div class="carousel__holder container container--resize">
            <h2 class="title title--02" v-if="heading">{{ heading }}</h2>
            <swiper
                :modules="[Autoplay]"
                :space-between="20"
                :autoplay="{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }"
                :speed="1000"
                loop
                :breakpoints="{
                    1920: { slidesPerView: 6 },
                    1440: { slidesPerView: 5 },
                    1200: { slidesPerView: 4 },
                    768: { slidesPerView: 3 },
                    480: { slidesPerView: 2 },
                    0: { slidesPerView: 1 },
                }"
            >
                <swiper-slide v-for="(item, index) in items" :key="String(item.id) + index">
                    <ProductCard
                        :store_product_image_path="item.store_product_image_path"
                        :product_name="item.product_name"
                        :selling_price="item.selling_price"
                        :is_in_favorite="true"
                        :currency="{ symbol: storeSettings.currency.symbol }"
                        :id="item.id"
                        :description="item.description"
                        :cost="item.cost"
                        :variants_count="item.variants_count"
                        :is_has_editable_text_area="item.is_has_editable_text_area"
                        :part_url="item.part_url"
                        :type="item.type"
                        :sku="item.sku"
                        :store_product_variant_id="item.store_product_variant_id"
                        :personalised_text="item.personalised_text"
                        :label="item.type === 'variable' && !item.is_has_editable_text_area ? 'Select options' : item.is_has_editable_text_area ? 'Personalise' : ''"
                        :combination="item.combination"
                        :reload_page="true"
                        :is_not_active="!item.is_available"
                        @onFavoriteClick="handleFavoriteClick(item.id)"
                        @onAddToCart="onAddToCart(item)"
                        @onLinkClick="onLinkClick"
                    />
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script src="./carousel.js" />
<style src="./carousel.scss" scoped lang="scss" />
