export default {
    navigation: [
        {
            id: 1,
            name: "Shop",
            path: "/shop",
            sub_categories: [],
        },
        {
            id: 2,
            name: "About",
            path: "/about",
            sub_categories: [],
        },
        {
            id: 3,
            name: "Categories",
            path: "/categories",
            sub_categories: [
                {
                    id: "cat3_sub1",
                    name: "Clothing",
                    path: "/category/clothing",
                },
                {
                    id: "cat3_sub2",
                    name: "General",
                    path: "/category/general",
                },
                {
                    id: "cat3_sub3",
                    name: "Valentines",
                    path: "/category/valentines",
                },
                {
                    id: "cat3_sub4",
                    name: "Bag",
                    path: "/category/clothing",
                },
                {
                    id: "cat3_sub4",
                    name: "Personalised",
                    path: "/category/personalised",
                },
                {
                    id: "cat3_sub4",
                    name: "Wellness",
                    path: "/category/wellness",
                },
                {
                    id: "cat3_sub4",
                    name: "Wel",
                    path: "/category/wel",
                },
                {
                    id: "cat3_sub4",
                    name: "Bag",
                    path: "/category/bag",
                },
            ],
        },
        {
            id: 4,
            name: "Delivery",
            path: "/delivery",
            sub_categories: [],
        },
        {
            id: 5,
            name: "Contact",
            path: "/contact-us",
            sub_categories: [],
        },
    ],
};
