<template>
    <div class="size-picker">
        <div class="size-picker__heading" v-if="heading">{{ heading }}</div>
        <div class="size-picker__list">
            <button class="size-picker__button" :class="{ 'size-picker__button--selected': selected === size }" v-for="size in sizes" :key="size" @click="handleClick(size)">
                <span class="size-picker__button--text">{{ size }}</span>
            </button>
        </div>
    </div>
</template>
<script src="./sizePicker.js"></script>
<style src="./sizePicker.scss" lang="scss" scoped />
