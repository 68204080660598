import { mapGetters } from "vuex";

export default {
    name: "About",
    components: {},
    data: () => {
        return {};
    },
    computed: {
        ...mapGetters("storeProps", { store_settings: "getStoreSettings", about_image_URL: "getAboutImageURL", about_text: "getAboutText" }),
        show() {
            return this.about_text || (this.store_settings && this.store_settings.about)
        }
    },
    mounted() {},
};
