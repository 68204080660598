<template>
    <div class="order-status">
        <div class="order-status__container">
            <div class="order-status__icon" :class="{'order-status__icon-warning': status === 'warning'}">
                <Icon :name="status === 'warning' ? 'warning' : status ? 'successStatus' : 'errorStatus'" />
            </div>
            <div class="order-status__status">{{ status === 'warning' ? '' : status ? "success" : "error" }}</div>
            <div class="order-status__description">
                <strong>{{ heading }}</strong>
                <p>
                    {{ description }}
                </p>
                <div class="order-status__details" v-if="success_data && success_data.order">
                    <ul>

                        <li><b>Order Status:</b> <span>{{success_data.order.order_status_name}}</span></li>
                        <li><b>Order Id:</b> <span>{{success_data.order.id}}</span></li>
                        <li><b>Order Products:</b> {{success_data.available_products_count}}</li>
                    </ul>
                    <ul>
                        <li><b>Subtotal:</b> <span>{{success_data.order.currency_symbol}}{{ParseCost(success_data.order.total - success_data.order.shipping)}}</span> </li>
                        <li><b>Shipping:</b> <span>{{success_data.order.currency_symbol}}{{ParseCost(success_data.order.shipping)}}</span> </li>
                        <li><b>Total:</b> <span>{{success_data.order.currency_symbol}}{{ParseCost(success_data.order.total)}}</span> </li>
                    </ul>
                </div>
                <div class="order-status__warning" v-if="success_data && success_data.unavailable_products_count">
                    <div class="order-status__warning-title">
                        <span>
                            <Icon name="warning" />
                        </span>
                        <strong>Warning</strong>
                    </div>
                    {{ success_data.unavailable_products_count === 1
                    ? "A product you previously added to your cart is now out-of-stock and has been removed."
                    : success_data.unavailable_products_count + " products you previously added to your cart are now out-of-stock and has been removed." }}
                </div>
            </div>

            <div class="order-status__button">
                <CustomButton :text="button_text" @onClick="handleClick" />
            </div>
        </div>
    </div>
</template>

<script src="./orderStatus.js"></script>
<style src="./orderStatus.scss" lang="scss"></style>
