export default class Helper {
  static getShopifyImageByName(image) {
    const file = image.split('/').pop().split('.').shift();
    const images = document.getElementsByTagName('img');
    for (let i = 0; i < images.length; i++) {
      if (images[i].dataset.srcset.includes(file)) {
        return images[i];
      }
    }
    return false;
  }

  static getShopifyImage() {
    const images = document.querySelectorAll('.image-container-personalise img');
    const pictures = document.querySelectorAll('picture source');
    const productImage =  document.querySelector('.image-container-personalise-single img');
    const productGalleryImageLoaded = document.querySelector('.product-gallery--loaded-image');

    if (productImage) {
      return [productImage];
    }

    if (images) {
        return images;
    }

    // if (images.length > 0) {
    //   for (let i = 0; i <= images.length; i++) {
    //     if (images[i].src.includes('/store_products/')) {
    //       const {
    //         left: l,
    //         right: r,
    //       } = images[i].getBoundingClientRect();
    //
    //       // Check for negative positions, this is
    //       // an indicator that it is a slider image
    //       if (l >= 0 && r >= 0) {
    //         return images[i];
    //       }
    //     }
    //   }
    // }

    // Check for picture element with srcset
    if (pictures.length > 0) {
      for (let i = 0; i <= pictures.length; i++) {
        if (pictures[i].srcset.includes('/products/')) {
          // Get the child img element
          const imgElement = pictures[i].parentElement.getElementsByTagName('img');

          if (imgElement.length > 0) {
            const {
              left: l,
              right: r,
            } = imgElement[0].getBoundingClientRect();

            // Check for negative positions, this is
            // an indicator that it is a slider image
            if (l >= 0 && r >= 0) {
              return imgElement[0];
            }
          }
        }
      }
    }

    // Check for standard slider class (loaded image)
    if (productGalleryImageLoaded.length !== null) {
      return productGalleryImageLoaded;
    }

    return false;
  }

  static setHiddenInputs(ele) {
    // Print Text
    const printText = document.createElement('input');
    printText.type = 'hidden';
    printText.name = 'properties[printText]';
    printText.classList.add('merchr-print-text');

    // Print Font
    const printFont = document.createElement('input');
    printFont.type = 'hidden';
    printFont.name = 'properties[printFont]';
    printFont.classList.add('merchr-print-font');

    // Print Colour
    const printColor = document.createElement('input');
    printColor.type = 'hidden';
    printColor.name = 'properties[printColor]';
    printColor.classList.add('merchr-print-color');

    // Append to add to cart form
    ele.prepend(printColor);
    ele.prepend(printFont);
    ele.prepend(printText);
  }

  static getLeftOffsetPx(productImagePreview, editableTextArea, image) {
    const areaOffsetPercent = parseFloat(productImagePreview.ptlx);
    const areaWidthPercent = parseFloat(productImagePreview.ptrx - productImagePreview.ptlx);
    const textAreaOffsetPercent = parseFloat(editableTextArea.leftPercent);
    const shopifyImageWidthPx = parseFloat(image.clientWidth);
    const calcOffsetPercent = areaOffsetPercent + (areaWidthPercent * textAreaOffsetPercent) / 100;

    return parseFloat((shopifyImageWidthPx * calcOffsetPercent) / 100).toFixed(3);
  }

  static getTopOffsetPx(productImagePreview, editableTextArea, image) {
    const areaOffsetPercent = parseFloat(productImagePreview.ptly);
    const areaHeightPercent = parseFloat(productImagePreview.pbly - productImagePreview.ptly);
    const textAreaOffsetPercent = parseFloat(editableTextArea.topPercent);
    const shopifyImageHeightPx = parseFloat(image.clientHeight);
    const calcOffsetPercent = areaOffsetPercent + (areaHeightPercent * textAreaOffsetPercent) / 100;

    return parseFloat((shopifyImageHeightPx * calcOffsetPercent) / 100).toFixed(3);
  }

  static getWidthPx(productImagePreview, editableTextArea, image) {
    const areaWidthPercent = parseFloat(productImagePreview.ptrx - productImagePreview.ptlx);
    const textAreaWidthPercent = parseFloat(editableTextArea.areaWidth);
    const shopifyImageWidthPx = parseFloat(image.clientWidth);
    const calculateWidthPercent = (textAreaWidthPercent * areaWidthPercent) / 100;

    return parseFloat((shopifyImageWidthPx * calculateWidthPercent) / 100).toFixed(3);
  }

  static getHeightPx(productImagePreview, editableTextArea, image) {
    const areaHeightPercent = parseFloat(productImagePreview.pbly - productImagePreview.ptly);
    const textAreaHeightPercent = parseFloat(editableTextArea.areaHeight);
    const shopifyImageHeightPx = parseFloat(image.clientHeight);
    const calculateHeightPercent = ((textAreaHeightPercent * areaHeightPercent) / 100) - 2;

    return parseFloat((shopifyImageHeightPx * calculateHeightPercent) / 100).toFixed(3);
  }

  static async hash(content) {
    const msgUint8 = new TextEncoder().encode(content);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }
}
