<template>
    <div class="product" v-if="!has_error">
        <Preloader is_global :show="is_loading" />
        <div class="product__holder container container--small">
            <div class="product__col">
                <div class="product__gallery" v-if="store_product?.type">
                    <ProductCarousel v-if="store_product?.type === 'variable'" :items="carousel_list" :selected_combination="selected_combination" @onSlideChange="onSlideChange" />
                    <div class="product__img image-container-personalise-single" v-else>
                        <img :src="store_product?.store_product_image_path" :alt="store_product?.product_name" />
                    </div>
                </div>
            </div>
            <div class="product__description" v-if="store_product && ((store_product.type === 'variable' && product_variant) || store_product.type === 'simple')">
                <div class="product__description-top">
                    <div class="product__description-header">
                        <div class="product__description-header-holder">
                            <h1 class="product__heading title title--01">{{ product_variant?.product_name || store_product.product_name }}</h1>
                            <div class="product__sub-heading">
                                {{ store_product.type !== "simple" ? `${store_product.product_name} |` : "" }} {{ storeSettings?.currency?.symbol
                                }}{{ parseCost(product_variant?.selling_price || store_product.selling_price) }}
                            </div>
                        </div>
                        <div class="product__description-header-actions">
                            <button class="product__description-header-btn" @click="toggleFavorite">
                                <Icon :name="favoriteProducts.find(p => p.id === store_product.id) ? 'iconFavoriteFull' : 'iconFavorite'" />
                            </button>
                            <button class="product__description-header-btn" @click="onShareButton">
                                <Icon name="share" />
                            </button>
                        </div>
                    </div>

                    <div class="product__options">
                        <div class="product__option" v-for="[, { id: option_id, name, values }] in Object.entries(current_options)" :key="name + option_id">
                            <div class="product__option-title">{{ name }}</div>
                            <div class="product__option-list">
                                <button
                                    class="product__option-item"
                                    :class="{ 'product__option-item--selected': Number(this.selected_options[option_id]) === Number(options_props.id) }"
                                    v-for="[option_item_id, options_props] in Object.entries(values)"
                                    :key="options_props.id"
                                    @click="onOptionsSelect({ option_id, option_item_id })"
                                >
                                    {{ options_props.name }}
                                    <span class="product__option-label" v-if="this.allowed_variants[option_id] && !checkExistedVariant(option_id, option_item_id)">Not allowed</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="personalise" v-if="personalised_text && personalised_text.text">
                        <h3 class="personalise__title title title--03">Personalise</h3>
                        <div class="personalise__form">
                            <div class="personalise__form-row">
                                <div class="personalise__form-col">
                                    <div class="personalise__form-label">Select to personalise</div>
                                    <div class="personalise__form-input-wrap">
                                        <div class="personalise__form-row">
                                            <div class="personalise__form-col">
                                                <div class="custom-radio" :class="{ 'custom-radio_active': personalise_text_state }" @click="handlePersonaliseTextState(true)">Personalise</div>
                                            </div>
                                            <div class="personalise__form-col">
                                                <div class="custom-radio" :class="{ 'custom-radio_active': !personalise_text_state }" @click="handlePersonaliseTextState(false)">Product only</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="personalise__form-row" :class="{ 'personalise__form-row_disabled': !personalise_text_state }">
                                <div class="personalise__form-col">
                                    <div class="personalise__form-label">Add your personalisation</div>
                                    <div class="personalise__form-input-wrap">
                                        <input type="text" class="personalise__form-input" :maxlength="limit" @input="handlePersonaliseText" v-model="print_params.text" />
                                    </div>
                                </div>
                            </div>
                            <div class="personalise__form-row" :class="{ 'personalise__form-row_disabled': !personalise_text_state }">
                                <div class="personalise__form-col">
                                    <div class="personalise__form-label">Text Colour</div>
                                    <div class="personalise__form-input-wrap">
                                        <colorPicker :colors="colors" :showColorSelector="true" :selected="print_params.color" @onClick="handleColor" @onColorPickerChange="updateColorPalette">
                                        </colorPicker>
                                    </div>
                                </div>
                                <div class="personalise__form-col">
                                    <div class="personalise__form-label">Font</div>
                                    <div class="personalise__form-input-wrap">
                                        <div class="custom-dropdown">
                                            <div class="custom-dropdown__label personalise__form-input" :style="{ fontFamily: print_params.font }" @click="font_dropdown_state = true">
                                                {{ print_params.font }}
                                            </div>
                                            <span class="custom-dropdown__icon"><Icon name="dropdown_arrow"/></span>
                                            <div class="custom-dropdown__options" v-if="font_dropdown_state">
                                                <div
                                                    class="custom-dropdown__options__item"
                                                    v-for="option in fonts"
                                                    :key="option"
                                                    @click="selectFontFamily(option)"
                                                    :style="{ fontFamily: option }"
                                                    :class="{ 'custom-dropdown__options__item_active': print_params.font === option }"
                                                >
                                                    {{ option }}
                                                </div>
                                            </div>
                                            <div class="custom-dropdown__overlay" v-if="font_dropdown_state" @click="font_dropdown_state = false"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="product__actions">
                    <div class="product__actions-holder">
                        <div class="product__actions-count">
                            <CountPicker :count="count" @onChange="onCountChange" />
                        </div>
                        <CustomButton :text="is_in_cart ? 'Update product' : 'Add to basket'" @onClick="onAddToCart" />
                    </div>
                    <div class="product__actions-status" v-if="is_in_cart"><Icon name="check" /> Already in cart</div>
                </div>
                <div class="product__details">
                    <div class="product__details-heading">Product Details</div>
                    <div class="product__details-holder">
                        <p v-html="product_variant?.description || store_product.description" />
                    </div>
                </div>
                <div class="product__footer">
                    <div class="product__footer-holder">
                        <span class="product__footer-sku">SKU: {{ product_variant?.sku || store_product.sku }}</span>
                        <div class="product__categories" v-if="product_categories">
                            <span class="product__categories-heading">Categories: </span>
                            <div class="product__categories-list">
                                <template v-for="{ name, id } in product_categories" :key="id">
                                    <span class="product__categories-item">{{ name }}</span
                                    >,
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="product__socials">
                        <div v-if="storeSettings.email" class="product__socials-link">
                            <SocialLink name="email" :url="'mailto:' + storeSettings.email" />
                        </div>
                        <template v-for="link in socials" :key="link.icon_name">
                            <div v-if="link.url" class="product__socials-link">
                                <SocialLink :name="link.icon_name" :url="link.url" />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ErrorView v-else />
    <Carousel
        heading="your favourites"
        v-if="favoriteProducts && favoriteProducts.length"
        :items="favoriteProducts.map(p => ({ ...p, is_in_favorite: true }))"
        @onLinkClick="openFavouriteProduct"
        @onAddToCart="onAddToCartFromFavorite"
    />
</template>
<script src="./ProductView.js"></script>
<style src="./ProductView.scss" lang="scss" scoped></style>
