import { mapGetters } from "vuex";
import parseCost from "@/helpers/parseCost.js";
import round from "@/helpers/round_number.js";

export default {
    name: "OrdersList",
    data: () => {
        return {};
    },
    methods: {
        optionsValues(options) {
            return Object.values(options);
        },
        parseCost,
        round,
    },
    computed: {
        ...mapGetters("products", {
            available_products: "getAvailableProducts",
            subtotal_price: "getAvailableProductsTotalPrice",
            shipping: "getShipping",
            products_count: "getAvailableProductsTotalCount",
        }),
        ...mapGetters("storeProps", { store_settings: "getStoreSettings" }),
    },
};
