import Helper from "@/helpers/personalise_text_helper";
import Canvas from "@/helpers/personalise_text_canvas";

let resizeHandler = null;

export default class PersonaliseTextService {
    async drawPersonalizeText(data, printParams, hideTextCanvas) {
        if (hideTextCanvas) {
            const image = await Helper.getShopifyImage();
            if (image === false) {
                throw new Error('No customisable image.');
            }
            image.forEach((img, index) => {
                new Canvas(img, index, true);
            })
            return
        }
        try {
            const image = await Helper.getShopifyImage();
            if (image === false) {
                throw new Error('No customisable image.');
            }

            // Set preview, design and text data
            let productImagePreview = data.preview;
            let editableTextArea = data.design;
            let editableTextData = data.text;

            // Create new Canvas object
            image.forEach((img, index) => {
                let canvas = new Canvas(img, index);

                // Position and size data
                const calculateDimensionsPx = () => {
                    const obj = {
                        leftOffset: Helper.getLeftOffsetPx(productImagePreview, editableTextArea, img),
                        topOffset: Helper.getTopOffsetPx(productImagePreview, editableTextArea, img),
                        width: Helper.getWidthPx(productImagePreview, editableTextArea, img),
                        height: Helper.getHeightPx(productImagePreview, editableTextArea, img),
                    };

                    return obj;
                };

                // Set position and size data
                let dimensionsPx = calculateDimensionsPx();
                canvas.drawText(dimensionsPx, printParams, editableTextArea, editableTextData);
                // resizeHandler = () => {
                //     canvas = new Canvas(image);
                //     dimensionsPx = calculateDimensionsPx();
                //     canvas.drawText(dimensionsPx, printParams, editableTextArea, editableTextData);
                // };
                // window.addEventListener('resize', resizeHandler);
            })

        } catch (error) {
            console.log(error);
        }
    }

    removeEventListener() {
        window.removeEventListener('resize', resizeHandler);
    }

    RGBAToHexA(orig) {
        var a, isPercent,
            rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
            alpha = (rgb && rgb[4] || "").trim(),
            hex = rgb ? "#" +
                (rgb[1] | 1 << 8).toString(16).slice(1) +
                (rgb[2] | 1 << 8).toString(16).slice(1) +
                (rgb[3] | 1 << 8).toString(16).slice(1) : orig;
        if (alpha !== "") {
            isPercent = alpha.indexOf("%") > -1;
            a = parseFloat(alpha);
            if (!isPercent && a >= 0 && a <= 1) {
                a = Math.round(255 * a);
            } else if (isPercent && a >= 0 && a <= 100) {
                a = Math.round(255 * a / 100)
            } else {
                a = "";
            }
        }
        if (a && a !== 1) {
            hex += (a | 1 << 8).toString(16).slice(1);
        }
        return hex;
    }
}
