import { mapActions, mapGetters } from "vuex";
import Icon from "@/components/ui/icon/icon.vue";
import menu from "@/config/header.const";
import INPUT_MAX_LIMIT from "@/config/inputs.const.js";

export default {
    components: {
        Icon,
    },

    data: () => {
        return {
            search_value: "",
            limit: INPUT_MAX_LIMIT,
            is_nav_shown: false,
            active_item_id: null,
            is_search_opened: false,
            search_field_is_empty: false,
            placeholder: "",
        };
    },
    methods: {
        ...mapActions("products", ["updateFilterParams"]),
        async filterByCategory(id) {
            await this.updateFilterParams({
                ...this.filter,
                product_category_ids: [id],
            });

            this.scrollToElement();
            this.onOverlayClick();
        },
        scrollToElement() {
            if (this.$route.path !== "/shop") {
                this.$router.push("/shop");
            }

            setTimeout(() => {
                const el = document.getElementById("productsList");

                if (el) {
                    const elementPosition = el.getBoundingClientRect().top + window.pageYOffset;

                    window.scrollTo({
                        top: elementPosition,
                        behavior: "smooth",
                    });

                    //el.scrollIntoView({ behavior: "smooth" });
                }
            }, 300);
        },
        animatePlaceholder(text = "") {
            this.placeholder = "";
            let index = 0;

            const interval = setInterval(() => {
                if (index < text.length) {
                    this.placeholder += text[index];
                    index++;
                } else {
                    clearInterval(interval);
                }
            }, 100);
        },
        async search() {
            if (!this.filter.search && !this.search_value && !this.search_field_is_empty) {
                this.search_field_is_empty = true;
                this.animatePlaceholder("SEARCH...");
            } else {
                await this.updateFilterParams({
                    ...this.filter,
                    search: this.search_value,
                });
                this.scrollToElement();
                this.is_search_opened = false;
            }
        },
        onOverlayClick() {
            this.onMenuOpenerClick(true);
            this.active_item_id = null;
        },
        onNavItemClick(id) {
            if (this.active_item_id === id) {
                this.active_item_id = null;
            } else {
                this.active_item_id = id;
            }
        },
        onMenuOpenerClick(no_scroll) {
            this.is_nav_shown = !this.is_nav_shown;
            this.is_search_opened = false;
            if (no_scroll) {
                const no_scroll_class_name = "no-scroll";
                const body_class_List = document.body.classList;
                this.is_nav_shown ? body_class_List.add(no_scroll_class_name) : body_class_List.remove(no_scroll_class_name);
            }
        },
        onFavoriteClick() {
            this.$router.push("/favorite");
        },
        onSearchOpenerClick() {
            if (this.is_nav_shown) {
                this.onOverlayClick(true);
            }
            this.is_search_opened = !this.is_search_opened;
        },
    },
    computed: {
        ...mapGetters("storeProps", { store_settings: "getStoreSettings", logoURL: "getLogoURL", store_name: "getStoreName", store_categories: "getStoreCategories" }),
        ...mapGetters("products", { filter: "getFilterParams", available_products_count: "getAvailableProductsTotalCount", items_in_cart: "getProductInCart" }),
        categories() {
            return [...menu.navigation].map(item => {
                if (item.id === 3) {
                    return {
                        ...item,
                        sub_categories: this.store_categories,
                    };
                } else {
                    return item;
                }
            });
        },
    },
    watch: {
        filter() {
            if (!this.filter.search) {
                this.search_value = "";
            }
        },
    },
};
