import { createRouter, createWebHistory } from "vue-router";

// views
import CommonContainer from "@/containers/common.vue";
import ShopView from "@/views/shop/ShopView.vue";
import CategoriesView from "@/views/categories/CategoriesView.vue";
import AboutView from "@/views/about/AboutView.vue";
import ProductPage from "@/views/product/ProductView.vue";
import ErrorView from "@/views/error/ErrorView.vue";
import DeliveryView from "@/views/delivery/DeliveryView.vue";
import ContactView from "@/views/contact/contactView.vue";
import ReturnsView from "@/views/returns/ReturnsView.vue";
import NotExistView from "@/views/notExistView/NotExistView.vue";
import CartView from "@/views/cart/CartView.vue";
import CheckoutView from "@/views/checkout/CheckoutView.vue";
import PrivacyPolicy from "@/views/privacyPolicy/PrivacyPolicy.vue";
import TermsAndCondition from "@/views/termsAndCondition/TermsAndCondition.vue";
import FavoriteView from "@/views/favorite/FavoriteView.vue";
import AccountView from "@/views/account/AccountView.vue";

async function checkStoreExistence(storeName) {
    const url_params = new URLSearchParams(window.location.search);
    const is_preview_mode = url_params.get("is_preview_mode");

    if (is_preview_mode) {
        return true;
    } else {
        const res = await fetch(`${process.env.VUE_APP_API_HOST_NAME}/api/website/store?store_url_local_sub=${process.env.VUE_APP_TEST_HOST_NAME || storeName}&type=main`).then(res => res.json());
        return !!res.data;
    }
}

async function guardMyRoute(to, from, next) {
    const storeName = process.env.VUE_APP_TEST_HOST_NAME || window.location.hostname.split(".")[0];

    const store_existed = await checkStoreExistence(storeName);

    if (!store_existed) {
        next("/not-exists");
    } else {
        next();
    }
}

const routes = [
    {
        path: "/",
        redirect: "/shop",
        component: CommonContainer,
        children: [
            { path: "shop", component: ShopView, beforeEnter: guardMyRoute },
            { path: "about", component: AboutView },
            { path: "categories", component: CategoriesView },
            { path: "delivery", component: DeliveryView },
            { path: "contact-us", component: ContactView },
            { path: "returns", component: ReturnsView },
            { path: "privacy", component: PrivacyPolicy },
            { path: "terms-and-conditions", component: TermsAndCondition },
            { path: "cart", component: CartView },
            { path: "checkout", component: CheckoutView },
            { path: "favorite", component: FavoriteView },
            { path: "account", component: AccountView },
            { path: "product/:part_url", component: ProductPage },
            { path: ":pathMatch(.*)*", component: ErrorView },
        ],
    },
    { path: "/not-exists", component: NotExistView },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.path === from.path && to.hash === from.hash) {
            return false;
        }

        if (savedPosition) {
            return savedPosition;
        }

        return { top: 0 };
    },
});

// router.beforeEach(function() {
//     setTimeout(() => {
//         window.scrollTo({ top: 0, behavior: "smooth" });
//     }, 100);
// });

export default router;
