import ApiService from "@/services/index";

const apiService = new ApiService();
const url = `${process.env.VUE_APP_API_HOST_NAME}/api/website/checkout/get-data`;

export default class cartList {
    async postProducts(params) {
        return await apiService.post({ url, params });
    }
}
