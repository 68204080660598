import ApiService from "@/services/index";

const apiService = new ApiService();

export const getStoreProps = async () => {
    //const hostname = "my-test" || window.location.hostname;
    const params = { type: "main" };
    const targetUrl = `${process.env.VUE_APP_API_HOST_NAME}/api/website/store`;
    return await apiService.get({ url: targetUrl, params });
};
