<template>
    <div class="contact custom-inner-pages">
        <div class="contact__wrapper">
            <h2 class="contact__title title--02">Contact us</h2>
            <div class="contact__description" v-if="!with_order_id">
                <p>Get in touch with us using the form below.</p>
                <p>Please don’t use this form for arranging returns, use <RouterLink to="/returns">this form</RouterLink> instead.</p>
            </div>
            <ContactUsForm />
        </div>
    </div>
</template>

<script src="./contactView.js"></script>
<style lang="scss" src="./contactView.scss" />
