import OrderForm from "./components/checkOrderForm/checkOrderForm.vue";
import OrderDetails from "./components/orderDetails/orderDetails.vue";
import ApiService from "@/services/index.js";
const apiService = new ApiService();
export default {
    name: "AccountView",
    components: { OrderForm, OrderDetails },
    data: () => ({
        order_data: null,
        error: null,
    }),
    methods: {
        clear() {
            this.order_data = null;
        },
        onInput() {
            if (this.error) {
                this.error = null;
            }
        },
        async getOrderData({ order_id, email }) {
            const params = { email, type: "order_show" };
            const { data, statusCode, error } = await apiService.get({ url: `${process.env.VUE_APP_API_HOST_NAME}/api/website/order/${order_id}/show`, params });
            if (data) {
                this.order_data = data?.order;
            }
            if (statusCode === 404) {
                this.error = error;
            }
        },
    },
};

// 3176
//
// Good@Good.com
