import {DotLottieVue} from "@lottiefiles/dotlottie-vue";

export default {
    name: "NotExistView",
    components: {DotLottieVue},
    data: () => {
        return {};
    },
    methods: {
        handleKeyDown(event) {
            if (event.ctrlKey && event.key === "r") {
                event.preventDefault();

                const host = window.location.host;

                if (host.includes("websitespa") || host.includes("localhost")) {
                    window.location.href = `${window.location.protocol}//${host}/`;
                }
            }
        },
    },
    mounted() {
        window.addEventListener("keydown", this.handleKeyDown);
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.handleKeyDown);
    },
};
