import Banner from "@/components/shared/banner/banner.vue";
import ContactView from "@/views/contact/contactView.vue";
import ContactUsForm from "@/components/shared/contactUsForm/contactUsForm.vue";

export default {
    name: "ReturnsView",
    components: {
        ContactUsForm,
        Banner,
        ContactView,
    },
    data: () => {
        return {};
    },
    methods: {},
};
