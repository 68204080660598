export default {
    name: "CountPicker",
    props: {
        count: {
            defaultValue: 0,
            type: Number,
        },
    },
    emits: {
        onChange: null,
    },
    methods: {
        handleChange(inc) {
            const newCount = this.count + inc < 1 ? this.count : this.count + inc;
            this.$emit("onChange", newCount);
        },
    },
};
