import CustomButton from "@/components/ui/customButton/customButton.vue";
import Preloader from "@/components/shared/preloader/preloader.vue";
import Banner from "@/components/shared/banner/banner.vue";
import INPUT_MAX_LIMIT from "@/config/inputs.const";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import ApiService from "@/services";

const emailValidation = helpers.regex(/^[0-9a-zA-Z+_~!#$%&‘./=^'{}|-]+@[a-zA-Z0-9._%+-]+\.[a-zA-Z]{2,}$/);

const apiService = new ApiService();

export default {
    name: "ContactUsForm",

    components: {
        Preloader,
        Banner,
        CustomButton,
    },
    props: {
        with_order_id: {
            type: Boolean,
            defaultValue: false,
        },
        buttonText: {
            type: String,
            defaultValue: "",
        },
    },
    data: () => {
        return {
            fields: [],
            is_loading: false,
            formData: {
                first_name: "",
                last_name: "",
                email: "",
                message: "",
                order_number: "",
            },
            limit: INPUT_MAX_LIMIT,
        };
    },
    validations() {
        return {
            formData: {
                first_name: { required },
                last_name: { required },
                email: { required, emailValidation },
                message: { required },
                order_number: { required },
            },
        };
    },
    methods: {
        check_is_valid_field(field_name) {
            return this.v$.formData[field_name].$error;
        },
        validateAllFields() {
            const validation_entry = this.v$.formData;
            validation_entry.$touch();
            const is_invalid = this.with_order_id
                ? this.v$.formData.$invalid
                : this.v$.formData.first_name.$invalid || this.v$.formData.last_name.$invalid || this.v$.formData.email.$invalid || this.v$.formData.message.$invalid;
            return !is_invalid;
        },
        clearFormData() {
            Object.keys(this.formData).forEach(field => {
                this.formData[field] = "";
            });
        },
        onInput(field_name, value) {
            this.formData[field_name] = value;
            this.v$.formData[field_name].$touch();
        },
        async submitContacts() {
            const is_valid = this.validateAllFields();
            if (!is_valid) return;
            this.is_loading = true;
            const url = `${process.env.VUE_APP_API_HOST_NAME}/api/website/contact-us?type=${this.with_order_id ? "return" : "feedback"}`;
            const { order_number, ...fields } = this.formData;
            const { status, message } = await apiService.post({
                url,
                params: this.with_order_id ? { order_number, ...fields, comment_or_message: this.formData.message } : { ...fields, comment_or_message: this.formData.message },
            });
            if (status === "success") {
                this.$notify({
                    title: message,
                    type: "success",
                });
                this.clearFormData();
                this.v$.formData.$reset();
            }
            this.is_loading = false;
        },
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    mounted() {
        this.fields = this.with_order_id
            ? [
                  { field_name: "first_name", label: "First name" },
                  { field_name: "last_name", label: "Last name" },
                  { field_name: "email", label: "Email" },
                  { field_name: "order_number", label: "Order Number" },
                  { field_name: "message", label: "Comment or Message" },
              ]
            : [
                  { field_name: "first_name", label: "First name" },
                  { field_name: "last_name", label: "Last name" },
                  { field_name: "email", label: "Email" },
                  { field_name: "message", label: "Comment or Message" },
              ];
    },
};
