<template>
    <div class="order">
        <h2 class="order__title title--03">Your Order ({{ products_count }})</h2>
        <div class="order__total">
            <div class="order__total-text">Total</div>
            <div class="order__total-price">
                <span>{{ store_settings.currency.symbol }}</span>
                <span>{{ parseCost(round(shipping + subtotal_price)) }}</span>
            </div>
        </div>
        <div class="order__list">
            <div class="order__item" v-for="item in available_products" :key="item.product.id">
                <div class="order__description">
                    <div class="order__description-content">
                        <div class="order__description-title">{{ item.product.product_name }}</div>
                        <div class="order__description-attributes" v-if="item.product.meta_data">
                            {{ optionsValues(item.product.meta_data?.options || {}).join(" , ") }}
                        </div>
                    </div>
                    <div class="order__personalization" v-if="item.product.meta_data && item.product.meta_data.customisation">
                        <div class="order__personalization-text" v-if="item.product.meta_data && item.product.meta_data.customisation.text">
                            Customization: <span>{{ item.product.meta_data.customisation.text }}</span>
                        </div>
                        <div class="order__personalization-styles">
                            <span class="order__personalization-style" v-if="item.product.meta_data && item.product.meta_data.customisation.colour">
                                Text Color:<span class="order__personalization-color" :style="{ '--product-text-color': item.product.meta_data.customisation.colour }">{{
                                    item.product.meta_data.customisation.colour
                                }}</span>
                            </span>
                            <span class="order__personalization-style" v-if="item.product.meta_data && item.product.meta_data.customisation.font">
                                Font: <span>{{ item.product.meta_data.customisation.font }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="order__details">
                    <div class="order__quantity">{{ item.count }}</div>
                    <div class="order__selling-price">
                        <span>{{ store_settings.currency.symbol }}</span>
                        <span>{{ parseCost(round(item.product.selling_price * item.count)) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./ordersList.js" />
<style src="./ordersList.scss" lang="scss" scoped />
