<template>
    <div class="banner" :class="{ 'banner--small': !(bannerURL || store_settings.bannerURL || store_settings.store_banner_image) }">
        <div class="banner__bg" :class="{ 'banner__bg-venture': themeType === 'venture' }" v-if="bannerURL || store_settings.bannerURL || store_settings.store_banner_image">
            <img :src="bannerURL || store_settings.bannerURL || store_settings.store_banner_image" alt="banner" />
            <div v-if="themeType === 'venture' && Object.values(social_links).some(item => item)" class="banner__icons">
                <template v-for="(url, icon_name) in social_links" :key="icon_name">
                    <SocialLink v-if="url" :name="icon_name" :url="url" />
                </template>
            </div>
        </div>
        <div v-if="themeType === 'venture'" class="banner__content">
            <div class="banner__content-title title--01" v-if="title">{{ title }}</div>
            <CustomButton :text="'Shop now'" @onClick="scrollToElement" />
            <div class="banner__arrow" @click="scrollToElement">
                <Icon name="arrow_to_bottom" />
            </div>
        </div>
    </div>
</template>

<script src="./banner.js"></script>
<style lang="scss" src="./banner.scss" />
