import Banner from "@/components/shared/banner/banner.vue";
import ContactView from "@/views/contact/contactView.vue";

export default {
    name: "PrivacyPolicy",
    components: {
        Banner,
        ContactView,
    },
    data: () => {
        return {};
    },
    methods: {},
};
