import { openDB } from "idb";

const DB_NAME = "SPA_DB";
const DB_VERSION = 1.0;
const OBS_NAME = "productsDataDB";
let DB;

export default class IndexedDbService {
    async getDb() {
        if (!("indexedDB" in window)) {
            throw new Error("Browser does not support IndexedDB");
        }
        // if installed from npm use 'openDB'
        return (DB = openDB(DB_NAME, DB_VERSION, {
            upgrade(db) {
                db.createObjectStore(OBS_NAME);
            },
        }));
    }

    async get(key) {
        return (await DB).get(OBS_NAME, key);
    }

    async set(key, val) {
        return (await DB).put(OBS_NAME, val, key);
    }

    async keys() {
        return (await DB).getAllKeys(OBS_NAME);
    }
    async getAll() {
        return (await DB).getAll(OBS_NAME);
    }
}
