<template>
    <div class="order-form">
        <div class="order-form__field">
            <InputField
                label="Order ID:"
                placeholder="Your Order ID"
                v-model="orderId"
                error_text="Field is required"
                :show_error="v$.orderId.$error && v$.orderId.$dirty"
                @input="e => onFieldChange({ field: 'orderId', value: e.target.value })"
            />
        </div>
        <div class="order-form__field">
            <InputField
                label="Email address:"
                placeholder="Your email address"
                v-model="email"
                :error_text="v$.email.emailValidation.$invalid ? 'Enter a valid email' : 'Field is required'"
                :show_error="v$.email.$error && v$.email.$dirty"
                @input="e => onFieldChange({ field: 'email', value: e.target.value })"
            />
        </div>
        <div class="order-form__error" v-if="error">
            {{ error?.description }}
        </div>
        <div class="order-form__footer">
            <CustomButton text="Submit" @onClick="onSubmit" />
        </div>
    </div>
</template>
<script src="./checkOrderForm.js" />
<style src="./checkOrderForm.scss" lang="scss" scoped />
