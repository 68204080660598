import Icon from "@/components/ui/icon/icon.vue";
import footerMixin from "@/components/shared/footer/footer.mixin.js";

export default {
    name: "VentureFooter",
    mixins: [footerMixin],
    components: {
        Icon,
    },
    data: () => {
        return {
        };
    },
};
