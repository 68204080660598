<template>
    <div class="input-field" :class="{ 'input-field--error': show_error }">
        <span class="input-field__label">{{ label }} <em class="input-field__label-marker" v-if="is_required">*</em> </span>
        <div class="input-field__holder">
            <input class="input-field__body" :maxlength="limit" v-if="!is_textarea" :type="type" :placeholder="placeholder" :name="name || placeholder" v-model="internalValue" @input="onInputChange" />
            <textarea class="input-field__body" :maxlength="limit" v-else :placeholder="placeholder" v-model="internalValue" @input="onInputChange" />
        </div>
        <div class="input-field__holder" v-if="extra_field">
            <input class="input-field__body input-field__body-not-required" :maxlength="limit" :type="type" :placeholder="extra_field_placeholder" v-model="extraFieldInternalValue" @input="onExtraFieldChange" />
        </div>
        <span class="input-field__error">{{ error_text }}</span>
    </div>
</template>
<script src="./inputField.js" />
<style src="./inputField.scss" lang="scss" scoped />
