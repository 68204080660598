import { mapActions, mapGetters } from "vuex";
import menu from "@/config/header.const";
import SocialLink from "@/components/ui/socialLink/socialLink.vue";
import INPUT_MAX_LIMIT from "@/config/inputs.const.js";

export default {
    components: {
        SocialLink,
    },
    data: () => {
        return {
            search_value: "",
            limit: INPUT_MAX_LIMIT,
        };
    },
    methods: {
        ...mapActions("products", ["updateFilterParams"]),
        filterByCategory(id) {
            this.applyFilter({ product_category_ids: [id] });
        },
        search() {
            this.applyFilter({ search: this.search_value });
        },
        applyFilter(newParams) {
            this.updateFilterParams({
                ...this.filter,
                ...newParams,
            });
            this.scrollToElement();
        },
        scrollToElement() {
            if (this.$route.path !== "/shop") {
                this.$router.push("/shop");
            }

            setTimeout(() => {
                const el = document.getElementById("productsList");

                if (el) {
                    const elementPosition = el.getBoundingClientRect().top + window.pageYOffset;

                    console.log("scr");
                    window.scrollTo({
                        top: elementPosition,
                        behavior: "smooth",
                    });

                    // Use el.scrollIntoView() to instantly scroll to the element
                    //el.scrollIntoView({ behavior: "smooth" });
                }
            }, 300);
        },
        onFavoriteClick() {
            this.$router.push("/favorite");
        },
    },
    computed: {
        ...mapGetters("products", { filter: "getFilterParams" }),
        ...mapGetters("storeProps", { store_settings: "getStoreSettings", logoURL: "getLogoURL", store_name: "getStoreName", store_categories: "getStoreCategories", social_links: "getSocialLinks" }),
        categories() {
            return [...menu.navigation].map(item => {
                if (item.id === 3) {
                    return {
                        ...item,
                        sub_categories: this.store_categories,
                    };
                } else {
                    return item;
                }
            });
        },
    },
    watch: {
        filter() {
            if (!this.filter.search) {
                this.search_value = "";
            }
        },
    },
};
