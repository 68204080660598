import INPUT_MAX_LIMIT from "@/config/inputs.const.js";

export default {
    name: "InputField",
    props: {
        modelValue: String,
        placeholder: {
            type: String,
            defaultValue: "",
        },
        label: {
            type: String,
            defaultValue: "",
        },
        type: {
            type: String,
            defaultValue: "text",
        },
        error_text: {
            type: String,
            defaultValue: "text",
        },
        show_error: {
            type: Boolean,
            defaultValue: false,
        },
        is_required: {
            type: Boolean,
            defaultValue: false,
        },
        is_textarea: {
            type: Boolean,
            defaultValue: false,
        },
        value: {
            type: String,
            defaultValue: "",
        },
        extra_field: {
            type: Boolean,
            defaultValue: false,
        },
        extra_field_value: {
            type: String,
            defaultValue: "",
        },
        extra_field_placeholder: {
            type: String,
            defaultValue: "",
        },
        name: {
            type: String,
            defaultValue: "",
        },
    },
    data() {
        return {
            limit: INPUT_MAX_LIMIT
        }
    },
    methods: {
        onInputChange({ target: { value } }) {
            this.$emit("onChange", value);
        },
        onExtraFieldChange({ target: { value } }) {
            this.$emit("onExtraChange", value);
        },
    },
    emits: ["onChange", "onExtraChange", "update:value", "update:extraFieldInternalValue"],
    computed: {
        internalValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:value", value);
            },
        },
        extraFieldInternalValue: {
            get() {
                return this.extra_field_value;
            },
            set(value) {
                this.$emit("update:extraFieldInternalValue", value);
            },
        },
    },
};
