<template>
    <div class="not-exist-page">
        <header class="not-exist-page__header">
            <img src="../../assets/images/merchr-logo.png" alt="Mymerchr logo" />
        </header>
        <main class="not-exist-page__content">
            <div class="not-exist-page__flex">
                <article class="not-exist-page__article">
                    <h1 class="not-exist-page__title">This store is nearly ready!</h1>
                    <p>If you've just built your merch site, please bear with us whilst we add those finishing touches to your unique online store!</p>
                    <p>When you check back soon, you might need to refresh your browser's cache:</p>
                    <div class="not-exist-page__reload-container">
                        <div class="not-exist-page__shift">
                            <img src="../../assets/images/shift.jpg" alt="Shift image" />
                            <div class="text-xs">Hold shift key</div>
                        </div>
                        <div class="not-exist-page__plus">+</div>
                        <div class="not-exist-page__refresh">
                            <img src="../../assets/images/reload.png" alt="Reload image" />
                            <div class="text-xs">Click refresh</div>
                        </div>
                    </div>
                </article>
                <aside class="not-exist-page__aside">
                    <DotLottieVue src="https://assets7.lottiefiles.com/packages/lf20_5tkzkblw.json" background="transparent" speed="1" loop autoplay />
                </aside>
            </div>
            <div class="not-exist-page__bottom">
                <p class="text-xs">
                    Not in the right place?
                    <br />
                    Check you've got the right store name if you are looking to buy merch or head to <a href="https://merchr.com/">merchr.com</a> to set up your own store!
                </p>
            </div>
        </main>
    </div>
</template>

<script src="./NotExistView.js"></script>
<style lang="scss" scoped src="./NotExistView.scss"></style>
