<template>
    <div class="card">
        <div class="card__info" :class="{ 'card__info--unavailable': unavailable }" @click="goToProduct(item.product)">
            <div class="card__image"><img :src="item.product.type === 'simple' ? item.product.store_product_image_path : item.product.store_product_image_url" :alt="item.product.product_name" /></div>
            <div class="card__description">
                <div class="card__description-content">
                    <div class="card__title">{{ item.product.product_name }}</div>
                    <div class="card__attributes">{{ options }}</div>
                </div>
                <div class="card__personalization" v-if="item.product.is_has_editable_text_area">
                    <div class="card__personalization-text" v-if="item.product.meta_data && item.product.meta_data.customisation.text">
                        Customization: <span>{{ item.product.meta_data.customisation.text }}</span>
                    </div>
                    <div class="card__personalization-styles">
                        <div class="card__personalization-style" v-if="item.product.meta_data && item.product.meta_data.customisation.colour">
                            Text Color:
                            <span class="card__personalization-color" :style="{ '--product-text-color': item.product.meta_data.customisation.colour }">{{
                                item.product.meta_data.customisation.colour
                            }}</span>
                        </div>
                        <div class="card__personalization-style" v-if="item.product.meta_data && item.product.meta_data.customisation.font">
                            Font: <span>{{ item.product.meta_data.customisation.font }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card__quantity" :class="{ 'card__info--unavailable': unavailable }">
            <CountPicker :count="item.count" @onChange="onCountChange" />
        </div>
        <div class="card__price" :class="{ 'card__info--unavailable': unavailable }">
            <div class="card__price-per-item">{{ store_settings.currency.symbol }}{{ parseCost(item.product.selling_price) }}</div>
            <div class="card__price-total-mobile" :class="{ 'card__info--unavailable': unavailable }">
                Total: <span> {{ store_settings.currency.symbol }} </span> <span> {{ parseCost(round(item.product.selling_price * item.count)) }}</span>
            </div>
        </div>
        <div class="card__price-total" :class="{ 'card__info--unavailable': unavailable }">{{ store_settings.currency.symbol }}{{ parseCost(round(item.product.selling_price * item.count)) }}</div>
        <div class="card__delete" @click="deleteItem">
            <span class="card__delete-icon" />
        </div>
    </div>
</template>

<script src="./productCardHorizontal.js" />
<style lang="scss" scoped src="./productCardHorizontal.scss" />
