import CustomButton from "@/components/ui/customButton/customButton.vue";
import Icon from "@/components/ui/icon/icon.vue";
import ParseCost from "@/helpers/parseCost.js"

export default {
    name: "OrderStatus",
    components: { CustomButton, Icon },
    props: {
        status: {
            default: false,
        },
        heading: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        button_text: {
            type: String,
            default: "",
        },
        success_data: {
            type: Object,
            default: {}
        }
    },
    emits: ["onClick"],
    methods: {
        handleClick() {
            this.$emit("onClick");
        },
        ParseCost
    },
};
