import Icon from "../icon/icon.vue";

export default {
    name: "SocialLink",
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            defaultValue: "",
        },
        url: {
            type: String,
            defaultValue: "",
        },
    },
    computed: {
        name_converted() {
            return this.name && this.name.toString().toLowerCase();
        }
    }
};
