import { mapActions, mapGetters } from "vuex";
import ProductCard from "@/components/shared/productCard/productCard.vue";
import Banner from "@/components/shared/banner/banner.vue";
import About from "@/components/shared/about/About.vue";
import AutoLoader from "@/components/shared/autoLoader/autoLoader.vue";
import Icon from "@/components/ui/icon/icon.vue";
import EmptyState from "@/components/shared/emptyState/emptyState.vue";
import Preloader from "@/components/shared/preloader/preloader.vue";
import Carousel from "@/components/shared/carousel/carousel.vue";

export default {
    name: "ShopView",
    components: { Carousel, Preloader, ProductCard, Banner, About, AutoLoader, Icon, EmptyState },
    data: () => ({
        order_by: [
            {
                id: "",
                name: "Default sorting",
            },
            {
                id: "created_at__asc",
                name: "Sort by popularity",
            },
            {
                id: "created_at__desc",
                name: "Sort by latest",
            },
            {
                id: "selling_price__asc",
                name: "Sort by price: low to high",
            },
            {
                id: "selling_price__desc",
                name: "Sort by price: high to low",
            },
        ],
        is_loading: true,
        sort_type: null,
    }),
    methods: {
        ...mapActions("products", [
            "setProductToCart",
            "updateProductInCart",
            "setProductToFavorite",
            "removeProductFromFavorite",
            "getProducts",
            "fetchFavoriteProducts",
            "updatePaginationPage",
            "cleanStore",
            "updateFilterParams",
        ]),

        async handleProductToFavorite(product) {
            product.is_in_favorite ? await this.removeProductFromFavorite(product.id) : await this.setProductToFavorite(product);
        },
        async onAddToCart(prod) {
            console.log("prod on shop", prod);
            const { product, count, is_available } = this.products_in_cart_hash[prod.id] || {};

            if (this.products_in_cart_hash[prod.id]) {
                await this.updateProductInCart({ product, count: count + 1, is_available });
            } else {
                await this.setProductToCart({ product: prod });
            }

            this.$notify({
                title: "Product was added to cart!",
                type: "success",
            });
        },
        checkProductIsFavorite(id) {
            return this.favoriteProducts.map(p => p.id).includes(id);
        },
        checkProductsIsDisabled(id) {
            return this.productsInCart && this.productsInCart.map(({ id }) => id).includes(id);
        },
        async onNextBatch(args) {
            this.updatePaginationPage(args);
            await this.getProducts();
        },
        openSortDropdown(val) {
            this.sort_type = val;
        },
        closeSortDropdown() {
            this.sort_type = null;
        },
        filterByCategories(id) {
            this.updateFilterParams({
                ...this.filter,
                product_category_ids: this.filter.product_category_ids.includes(id)
                    ? [...this.filter.product_category_ids.filter(item => item !== id)]
                    : [...this.filter.product_category_ids, ...[id]],
            });
        },
        clearFilter() {
            this.updateFilterParams({
                order_by: "",
                product_category_ids: [],
                search: "",
            });
        },
        orderBy(id) {
            this.updateFilterParams({
                ...this.filter,
                order_by: id,
            });
            this.sort_type = null;
        },
    },
    computed: {
        ...mapGetters("products", {
            products: "getProducts",
            favoriteProducts: "getFavoriteProducts",
            pagination: "getPagination",
            isProductLoading: "getIsLoading",
            filter: "getFilterParams",
            productsInCart: "getProductInCart",
            products_in_cart_hash: "getProductsInCartHash",
        }),
        ...mapGetters("storeProps", {
            storeSettings: "getStoreSettings",
            themeType: "getThemeType",
            previewMode: "getPreviewMode",
            store_categories: "getStoreCategories",
        }),
        order_by_label() {
            return this.order_by.find(item => item.id === this.filter.order_by).name;
        },
        store_categories_hash() {
            const result = {};
            this.store_categories.forEach(item => (result[item.id] = item.name));
            return result;
        },
        is_product() {
            return this.products.length !== 0;
        },
        is_filter() {
            return (this.products.length === 0 && Boolean(this.filter.order_by)) || Boolean(this.filter.product_category_ids.length) || Boolean(this.filter.search);
        },
    },
    async mounted() {
        await this.getProducts();
        await this.fetchFavoriteProducts();
        this.is_loading = false;
    },
    unmounted() {
        this.cleanStore();
    },
};
