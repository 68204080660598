import { mapActions, mapGetters } from "vuex";
import ColorPicker from "../../components/shared/colorPicker/colorPicker.vue";
import SizePicker from "../../components/shared/sizePicker/sizePicker.vue";
import ProductCarousel from "../../components/shared/productCarousel/productCarousel.vue";
import CustomButton from "@/components/ui/customButton/customButton.vue";
import CountPicker from "@/components/shared/countPicker/countPicker.vue";
import Icon from "@/components/ui/icon/icon.vue";
import PersonaliseTextService from "@/services/personalise_text.service.js";
import { COLORS } from "@/config/colors.const.js";
import { FONTS } from "@/config/fonts.const.js";
import ErrorView from "@/views/error/ErrorView.vue";
import Carousel from "@/components/shared/carousel/carousel.vue";
import copy from "copy-to-clipboard";
import SocialLink from "@/components/ui/socialLink/socialLink.vue";
import INPUT_MAX_LIMIT from "@/config/inputs.const.js";
import Preloader from "@/components/shared/preloader/preloader.vue";
import parseCost from "@/helpers/parseCost.js";

const personaliseTextService = new PersonaliseTextService();

export default {
    name: "ProductPage",
    components: { Preloader, ErrorView, Icon, CountPicker, CustomButton, ColorPicker, SizePicker, ProductCarousel, SocialLink, Carousel },
    data: () => ({
        count: 1,
        categories: ["Clothing", "General", "Spitfire"],
        socials: [
            { icon_name: "email", url: null, key: "email" },
            { icon_name: "whatsapp", url: null, key: "whatsapp" },
            { icon_name: "facebook_big", url: null, key: "facebook" },
            { icon_name: "x", url: null, key: "twitter" },
            { icon_name: "linkedin", url: null, key: "linkedin" },
            { icon_name: "telegram", url: null, key: "telegram" },
            { icon_name: "pinterest", url: null, key: "pinterest" },
            { icon_name: "instagram", url: null, key: "instagram" },
            { icon_name: "youtube", url: null, key: "youtube" },
            { icon_name: "tiktok", url: null, key: "tikTok" },
        ],
        carousel_list: [],
        current_options: {},
        selected_options: {},
        allowed_variants: {},
        selected_combination: "",
        product_variant: null,
        is_in_cart: false,
        colors: [],
        fonts: FONTS,
        print_params: {
            text: null,
            color: null,
            font: null,
        },
        personalised_text: null,
        personalise_text_state: false,
        font_dropdown_state: false,
        limit: INPUT_MAX_LIMIT,
        is_loading: true,
    }),
    methods: {
        ...mapActions("product", ["fetchProductData", "setHasError"]),
        ...mapActions("products", ["setProductToFavorite", "removeProductFromFavorite", "fetchFavoriteProducts", "updateProductInCart", "setProductToCart"]),
        //...mapActions("cart", ["setProductToCart"]),
        onCountChange(newCount) {
            this.count = newCount;
        },
        parseCombination(comb) {
            const combination_keys = Object.keys(this.current_options);
            this.selected_options =
                this.store_product.type === "variable"
                    ? comb.split("-").reduce((acc, current, index) => {
                          acc[combination_keys[index]] = Number(current);
                          return acc;
                      }, {})
                    : null;
        },
        checkExistedVariant(option_id, option_item_id) {
            if (Object.keys(this.allowed_variants).length) {
                return this.allowed_variants[option_id]?.includes(Number(option_item_id));
            }
        },
        onShareButton() {
            const product_url = window.location.href;

            copy(product_url, {
                debug: true,
                message: "Press #{key} to copy",
            });

            this.$notify({
                title: "Link was copied!",
                text: "Now you can share it with other",
                type: "success",
            });
        },
        async selectProductVariant() {
            if (this.store_product.type === "variable") {
                const { store_product_variant_id } = this.combinations[this.selected_combination] || {};

                if (store_product_variant_id) {
                    const { store_product_variants } = this.store_product;
                    this.product_variant = store_product_variants.find(({ id }) => id === store_product_variant_id);
                } else {
                    this.product_variant = this.store_product.store_product_variants.find(({ id }) => id === store_product_variant_id);
                }
            }
            await this.setPersonalizeTextOptions();
        },
        createURLParams() {
            const current_route = this.$route.path;
            const personalize_text =
                this.personalised_text && this.personalised_text.text
                    ? `&text=${this.print_params.text}&font=${this.print_params.font}&color=${this.print_params.color.substring(1)}&personalise=${this.personalise_text_state}`
                    : "";
            this.$router.push(`${current_route}?combination=${this.selected_combination}${personalize_text}`);
        },
        onOptionsSelect({ option_id, option_item_id }) {
            const option_list = Object.entries(this.current_options);
            const rest_options = option_list.filter(([opt_id]) => Number(opt_id) !== option_id);
            this.selected_options[option_id] = option_item_id;
            this.allowed_variants = this.current_options[option_id].values[option_item_id].allowed;
            rest_options.forEach(([opt_id]) => {
                if (
                    !this.selected_options[opt_id] ||
                    (this.selected_options[opt_id] && !this.current_options[option_id].values[option_item_id].allowed[opt_id].includes(Number(this.selected_options[opt_id])))
                ) {
                    this.selected_options[opt_id] = Number(this.current_options[option_id].values[option_item_id].allowed[opt_id][0]);
                }
            });

            this.selected_combination = Object.values(this.selected_options).join("-");
            this.createURLParams();
            this.selectProductVariant();
            this.checkProductIsInCart();
        },
        onSlideChange(value) {
            this.selected_combination = value;
            this.parseCombination(value);
            this.createURLParams();
            this.selectProductVariant();
            this.checkProductIsInCart();
        },
        toggleFavorite() {
            this.favoriteProducts.find(p => p.id === this.store_product.id) ? this.removeProductFromFavorite(this.store_product.id) : this.setProductToFavorite(this.store_product);
        },
        checkProductIsInCart() {
            let product_is_in_cart = null;
            if (this.store_product.type === "variable") {
                product_is_in_cart = this.productsInCart.find(({ product }) => {
                    const { colour, text, font } = product.meta_data?.customisation || {};
                    const check_equal_text_params = this.personalise_text_state
                        ? this.print_params.color === colour && text === this.print_params.text && font === this.print_params.font
                        : [colour, text, font].every(v => !v);
                    return (
                        product.id === this.store_product.id &&
                        product.store_product_variant_id === this.product_variant.id &&
                        product.combination === this.selected_combination &&
                        check_equal_text_params
                    );
                });
            }
            if (this.store_product.type === "simple") {
                product_is_in_cart = this.productsInCart.find(({ product }) => {
                    const { colour, text, font } = product.meta_data?.customisation || {};
                    const check_equal_text_params = this.personalise_text_state
                        ? this.print_params.color === colour && text === this.print_params.text && font === this.print_params.font
                        : [colour, text, font].every(v => !v);
                    return product.id === this.store_product.id && check_equal_text_params;
                });
            }
            if (product_is_in_cart) {
                this.count = product_is_in_cart.count;
            } else {
                this.count = 1;
                this.is_in_cart = false;
            }

            this.is_in_cart = !!product_is_in_cart;
        },
        async onAddToCart() {
            const options = Object.entries(this.current_options).reduce((acc, [key, props]) => {
                acc[props.name] = props.values[this.selected_options[key]]?.name;
                return acc;
            }, {});
            const meta_data = {
                customisation: {
                    type: this.personalise_text_state ? "text" : null, // null if there is no customisation text
                    // "thumbnail": "url", // ?? // null if there is no customisation text
                    text: this.personalise_text_state ? this.print_params.text : null, // null if there is no customisation text
                    colour: this.personalise_text_state ? this.print_params.color : null, // null if there is no customisation text
                    font: this.personalise_text_state ? this.print_params.font : null, // null if there is no customisation text
                },
                options: options,
            };
            const current_product = {
                count: this.count,
                product:
                    this.store_product.type === "variable"
                        ? {
                              ...this.product_variant,
                              part_url: this.$route.params.part_url,
                              type: this.store_product.type,
                              store_product_variant_id: this.product_variant.id,
                              combination: this.selected_combination,
                              id: this.store_product.id,
                              meta_data,
                              personalise_state: this.print_params.text ? this.personalise_text_state : null,
                          }
                        : { ...this.store_product, meta_data, personalise_state: this.print_params.text ? this.personalise_text_state : null },
            };

            if (!this.is_in_cart) {
                await this.setProductToCart(current_product);
                this.checkProductIsInCart();
                this.$notify({
                    title: "Product was added to cart!",
                    type: "success",
                });
            } else {
                await this.updateProductInCart(current_product);
                this.$notify({
                    title: "Product in cart was updated!",
                    type: "success",
                });
            }
            //this.$router.push("/cart");
        },
        async setPersonalizeTextOptions() {
            const { personalised_text } = this.store_product.type === "variable" ? this.product_variant : this.store_product;
            this.personalised_text = personalised_text;
            await this.drawPersonalizeText();
        },
        async drawPersonalizeText() {
            await personaliseTextService.drawPersonalizeText(this.personalised_text, this.print_params, !this.personalise_text_state);
        },
        setInitialPrintParams() {
            const { text, font, color, personalise } = this.$route.query;
            const personalise_state = personalise === "true";
            const { personalised_text } = this.store_product.type === "variable" ? this.product_variant : this.store_product;
            this.personalised_text = personalised_text;

            if (text) {
                this.print_params = {
                    text,
                    color: color.includes("#") ? color : "#" + color,
                    font,
                };
                this.personalise_text_state = !!personalise_state;
                this.colors = COLORS.includes(this.print_params.color) ? [...COLORS] : [...COLORS, ...[this.print_params.color]];
            }

            if (personalised_text && personalised_text.text && (!text || !personalise)) {
                this.print_params = {
                    ...personalised_text.text,
                    color: personaliseTextService.RGBAToHexA(personalised_text.text.color),
                };
                this.personalise_text_state = personalise ? personalise_state : true;
                this.colors = COLORS.includes(this.print_params.color) ? [...COLORS] : [...COLORS, ...[this.print_params.color]];
            }
        },
        updateColorPalette(color) {
            if (!this.colors.includes(color)) {
                this.colors.push(color);
            }
        },
        async handleColor(color) {
            this.print_params.color = color;
            await this.drawPersonalizeText();
            this.createURLParams();
            this.checkProductIsInCart();
        },
        async selectFontFamily(font) {
            this.print_params.font = font;
            this.font_dropdown_state = false;
            await this.drawPersonalizeText();
            this.createURLParams();
            this.checkProductIsInCart();
        },
        async handlePersonaliseText() {
            await this.drawPersonalizeText();
            this.createURLParams();
            this.checkProductIsInCart();
        },
        async handlePersonaliseTextState(value) {
            this.personalise_text_state = value;
            await this.drawPersonalizeText();
            this.createURLParams();
            this.checkProductIsInCart();
        },
        setSocialLinks() {
            this.socials = this.socials.map(link => {
                return {
                    ...link,
                    url: this.social_links[link.key],
                };
            });
        },
        async onAddToCartFromFavorite(prod) {
            const { product, count, is_available } = this.products_in_cart_hash[prod.id] || {};

            if (this.products_in_cart_hash[prod.id]) {
                await this.updateProductInCart({ product, count: count + 1, is_available });
            } else {
                await this.setProductToCart({ product: prod });
            }

            this.$notify({
                title: "Product was added to cart!",
                type: "success",
            });
        },
        async initialSetup() {
            const { combination } = this.$route.query;

            const part_url = this.$route.params.part_url;
            await this.fetchProductData({ part_url });

            if (!this.has_error) {
                this.current_options = this.option_values[this.store_product?.id];
                if (combination && this.combinations[combination]) {
                    this.parseCombination(combination);
                    this.selected_combination = combination;
                } else {
                    const initial_product_id = this.store_product.store_product_variants?.find(({ is_main }) => is_main === 1)?.id || null;
                    const first_combination = initial_product_id
                        ? Object.entries(this.combinations).find(([, { store_product_variant_id }]) => store_product_variant_id === initial_product_id)?.[0]
                        : null;
                    this.selected_combination = first_combination || this.store_product.combination;
                    this.parseCombination(this.selected_combination);
                    this.createURLParams();
                }
                this.selectProductVariant();
                this.checkProductIsInCart();
                this.setInitialPrintParams();
                this.setSocialLinks();
                await this.fetchFavoriteProducts();
                setTimeout(async () => {
                    await this.setPersonalizeTextOptions();
                }, 900);
                this.is_loading = false;
            }
        },
        openFavouriteProduct() {
            this.is_loading = true;
        },
        parseCost
    },
    computed: {
        ...mapGetters("storeProps", { storeSettings: "getStoreSettings", social_links: "getSocialLinks" }),
        ...mapGetters("product", {
            option_values: "getOptionValues",
            combinations: "getCombinations",
            store_product: "getStoreProductData",
            product_categories: "getProductCategories",
            has_error: "getHasError",
            error_text: "getErrorText",
        }),
        ...mapGetters("products", { productsInCart: "getProductInCart", products_in_cart_hash: "getProductsInCartHash", favoriteProducts: "getFavoriteProducts" }),
    },
    watch: {
        combinations() {
            this.carousel_list = Object.entries(this.combinations);

            const initial_combination = this.$route.query.combination;
            if (!initial_combination) {
                this.parseCombination(Object.keys(this.combinations)[0]);
                this.createURLParams();
            }
        },
        option_values() {
            this.current_options = this.option_values[this.store_product.id];
        },
    },
    async mounted() {
        await this.initialSetup();
    },
    beforeUnmount() {
        this.setHasError(false);
    }
};
