import CustomButton from "@/components/ui/customButton/customButton.vue";
import Icon from "@/components/ui/icon/icon.vue";
import parseCost from "@/helpers/parseCost.js";
import { mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutService from "@/services/checkout.service";
import round from "@/helpers/round_number.js";
import Checkbox from "@/components/ui/checkbox/checkbox.vue";
import shipping_exchange_map from "../../../../helpers/shipping_exchange_map";

const checkoutService = new CheckoutService();

export default {
    name: "Summary",
    components: {
        Icon,
        CustomButton,
        Checkbox,
    },
    data: () => {
        return {
            //stripe fields
            stripe: null,
            elements: null,
            errors: {},
            setup_intent: null,
            // end stripe

            new_credit_card: {
                card_number: "",
                date: "",
                code: "",
                // first_name: "",
                // last_name: "",
                // first_address: "",
                // second_address: "",
                // city: "",
                // postcode: "",
                // mobile: "",
            },
            is_standard_shipping: true,
            is_new_card: true,
            terms_conditions: false,
        };
    },
    methods: {
        openFormCreditCard() {
            this.is_new_card = true;
        },
        closeFormCreditCard() {
            this.is_new_card = false;
        },
        parseCost,
        round,
        setUpStripe() {
            const primaryTextColor = getComputedStyle(document.documentElement).getPropertyValue("--primary-text-color").trim();
            const generalPlaceholder = getComputedStyle(document.documentElement).getPropertyValue("--general-placeholder").trim();

            const style = {
                base: {
                    color: primaryTextColor,
                    "::placeholder": {
                        color: generalPlaceholder,
                    },
                    invalid: {
                        color: "#FF0000",
                        iconColor: "#FF0000",
                    },
                },
            };

            this.new_credit_card.card_number = this.stripeElements.create("cardNumber", { style, showIcon: true, placeholder: "1234 1234 1234 1234" });
            this.new_credit_card.card_number.mount("#card-number");
            this.new_credit_card.date = this.stripeElements.create("cardExpiry", { style, placeholder: "MM/YY" });
            this.new_credit_card.date.mount("#card-date");
            this.new_credit_card.code = this.stripeElements.create("cardCvc", { style });
            this.new_credit_card.code.mount("#card-cvc");
            this.new_credit_card.card_number.addEventListener("change", this.validateCardNumber);
            this.new_credit_card.date.addEventListener("change", this.validateCardExpiry);
            this.new_credit_card.code.addEventListener("change", this.validateCardCvc);
        },
        async preparePaymentCard() {
            try {
                const setupIntentResponse = await this.setupIntent();
                const { setupIntent } = await this.stripe.confirmCardSetup(setupIntentResponse.client_secret, {
                    payment_method: {
                        card: this.new_credit_card.card_number,
                        billing_details: {},
                    },
                });
                this.errors = {};
                return setupIntent;
            } catch (error) {
                throw new Error();
            }
        },
        async setupIntent() {
            return await checkoutService.setupIntent(process.env.VUE_APP_STRIPE_KEY);
        },
        validateCardNumber(event) {
            this.handleErrors(event, "cardNumber");
        },
        validateCardExpiry(event) {
            this.handleErrors(event, "cardExpiry");
        },
        validateCardCvc(event) {
            this.handleErrors(event, "cardCvc");
        },
        changeTermsConditions(val) {
            this.terms_conditions = val;
            this.errors.terms_conditions = {
                valid: this.terms_conditions === true,
                empty: this.terms_conditions === false,
            };
        },
        handleErrors(event, type) {
            this.errors = {
                ...this.errors,
                [type]: {
                    empty: event.empty,
                    valid: event.complete,
                },
            };
        },
        validatePaymentCard() {
            this.errors = {
                cardNumber: {
                    empty: this.stripeElements.getElement("cardNumber")._empty,
                    valid: !this.stripeElements.getElement("cardNumber")._invalid,
                },
                cardExpiry: {
                    empty: this.stripeElements.getElement("cardExpiry")._empty,
                    valid: !this.stripeElements.getElement("cardExpiry")._invalid,
                },
                cardCvc: {
                    empty: this.stripeElements.getElement("cardCvc")._empty,
                    valid: !this.stripeElements.getElement("cardCvc")._invalid,
                },
                terms_conditions: {
                    valid: this.terms_conditions === true,
                    empty: this.terms_conditions === false,
                },
            };
            return (
                !(this.stripeElements.getElement("cardNumber")._invalid || this.stripeElements.getElement("cardNumber")._empty) &&
                !(this.stripeElements.getElement("cardExpiry")._invalid || this.stripeElements.getElement("cardExpiry")._empty) &&
                !(this.stripeElements.getElement("cardCvc")._invalid || this.stripeElements.getElement("cardCvc")._empty) &&
                this.terms_conditions
            );
        },
    },
    async mounted() {
        this.stripe = await loadStripe("pk_live_51LDE3fD2JydgD2AQurreNCugsbGgqLa25R3qW9wFtYB5mh4GfWxkqOm0Qu6E6Z1qv1Bg41t2xO8bAwMuH3KEKVMk001koaWhZ9");
        this.setUpStripe();
    },
    computed: {
        shipping_exchange_map() {
            return shipping_exchange_map;
        },
        ...mapGetters("products", { subtotal_price: "getAvailableProductsTotalPrice", shipping: "getShipping" }),
        ...mapGetters("storeProps", { store_settings: "getStoreSettings" }),
        stripeElements() {
            return this.stripe.elements();
        },
    },
};
