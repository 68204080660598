import InputField from "@/components/ui/inputField/inputField.vue";
import Checkbox from "@/components/ui/checkbox/checkbox.vue";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const deepCopy = data => JSON.parse(JSON.stringify(data));

const phoneValidation = helpers.regex(/^[\d+ ]*$/);
const emailValidation = helpers.regex(/^[0-9a-zA-Z+_~!#$%&‘./=^'{}|-]+@[a-zA-Z0-9._%+-]+\.[a-zA-Z]{2,}$/);

export default {
    name: "BillingDetails",
    components: { InputField, Checkbox },
    props: {
        billing_details: {
            type: Object,
        },
        shipping_details: {
            type: Object,
        },
        is_deliver_to_different_address: {
            type: Number,
            defaultValue: 0,
        },
        customer_note: {
            type: String,
            defaultValue: "",
        },
    },
    data: () => ({
        billing_details_data: {
            first_name: "",
            last_name: "",
            company: "",
            billing_street: "",
            billing_apartment: "",
            billing_country: "",
            billing_state: "",
            billing_city: "",
            billing_zip_code: "",
            phone: "",
            email: "",
        },
        shipping_details_data: {
            shipping_first_name: "",
            shipping_last_name: "",
            shipping_street: "",
            shipping_apartment: "",
            shipping_country: "",
            shipping_city: "",
            shipping_zip_code: "",
            shipping_state: "",
        },
    }),
    validations() {
        return {
            billing_details_data: {
                first_name: {
                    required,
                },
                last_name: {
                    required,
                },
                billing_street: {
                    required,
                },
                billing_country: {
                    required,
                },
                billing_city: {
                    required,
                },
                billing_zip_code: {
                    required,
                },
                phone: {
                    phoneValidation,
                },
                email: {
                    required,
                    emailValidation,
                },
            },
            shipping_details_data: {
                shipping_first_name: {
                    required,
                },
                shipping_last_name: {
                    required,
                },
                shipping_street: {
                    required,
                },
                shipping_country: {
                    required,
                },
                shipping_city: {
                    required,
                },
                shipping_zip_code: {
                    required,
                },
            },
        };
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    methods: {
        onBillingFieldChange({ value, field }) {
            this.$emit("onBillingDetailsChange", { ...deepCopy(this.billing_details), [field]: value });
            this.billing_details_data[field] = value;

            this.v$.billing_details_data?.[field]?.$touch();
        },
        onShippingFieldChange({ value, field }) {
            this.$emit("onShippingDetailsChange", { ...deepCopy(this.shipping_details), [field]: value });
            this.shipping_details_data[field] = value;
            this.v$.shipping_details_data?.[field]?.$touch();
        },
        onCheckboxChange(state) {
            this.$emit("onCheckboxChange", Number(state));
        },
        validateBillingDetails() {
            if (this.is_deliver_to_different_address) {
                this.v$.billing_details_data.$touch();
                this.v$.shipping_details_data.$touch();
                return !this.v$.$invalid;
            } else {
                this.v$.billing_details_data.$touch();
                return !this.v$.billing_details_data.$invalid;
            }
        },
    },
    emits: {
        onBillingDetailsChange: null,
        onShippingDetailsChange: null,
        onChangeCustomerNote: null,
        onCheckboxChange: null,
        validationCallback: null,
    },
    mounted() {
        this.billing_details_data = this.billing_details;
        this.shipping_details_data = this.shipping_details;
        this.$emit("validationCallback", this.validateBillingDetails);
    },
};
