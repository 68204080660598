import InputField from "@/components/ui/inputField/inputField.vue";
import CustomButton from "@/components/ui/customButton/customButton.vue";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
const emailValidation = helpers.regex(/^[0-9a-zA-Z+_~!#$%&‘./=^'{}|-]+@[a-zA-Z0-9._%+-]+\.[a-zA-Z]{2,}$/);

export default {
    name: "OrderForm",
    components: { CustomButton, InputField },
    props: {
        error: null,
    },
    data: () => ({
        email: "",
        orderId: "",
    }),
    validations() {
        return {
            email: { required, emailValidation },
            orderId: { required },
        };
    },
    setup() {
        const v$ = useVuelidate();
        return { v$ };
    },
    methods: {
        onFieldChange({ field, value }) {
            this[field] = value;
            this.$emit("onInput");
        },
        checkFieldsBeforeRequest() {
            this.v$.email.$touch();
            this.v$.orderId.$touch();
            return !this.v$.$invalid;
        },
        onSubmit() {
            const is_valid = this.checkFieldsBeforeRequest();
            if (is_valid) {
                this.$emit("onSubmit", { email: this.email, order_id: this.orderId });
            }
        },
    },
    emits: {
        onSubmit: null,
        onInput: null,
    },
};
