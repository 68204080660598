import productCardMixin from "../../productCard.mixin";
import parseCost from "../../../../../helpers/parseCost";
import CustomButton from "@/components/ui/customButton/customButton.vue";

export default {
    name: "StrideProductCard",
    components: { CustomButton },
    methods: { parseCost },
    mixins: [productCardMixin],
};
