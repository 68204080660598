import CustomButton from "@/components/ui/customButton/customButton.vue";
import productCardMixin from "../../productCard.mixin";
import parseCost from "../../../../../helpers/parseCost";

export default {
    name: "VentureProductCard",
    methods: { parseCost },
    components: { CustomButton },
    mixins: [productCardMixin],
};
