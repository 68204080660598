export default class Canvas {
  constructor(image, index, hide) {
    if (document.getElementById('merchr-canvas' + index)) {
      document.getElementById('merchr-canvas' + index).remove();
      if (hide) {
          return;
      }
    }
    this.image = image;
    this.canvas = document.createElement('canvas');
    this.canvasWidth = this.image.clientWidth * devicePixelRatio;
    this.canvasHeight = this.image.clientHeight * devicePixelRatio;
    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;
    this.canvas.setAttribute('id', 'merchr-canvas' + index);
    this.canvas.style.width = `${image.clientWidth}px`;
    this.canvas.style.height = `${image.clientHeight}px`;
    this.canvas.style.position = 'absolute';
    this.canvas.style.pointerEvents = 'none';
    this.canvas.style.left = 0;
    this.canvas.style.top = 0;
    this.canvas.style.zIndex = 2;
    this.imageParent = this.image.parentElement;
    this.imageParent.style.position = 'relative';
    this.imageParent.append(this.canvas);
    this.ctx = document.getElementById('merchr-canvas' + index).getContext('2d');
    this.ctx.scale(devicePixelRatio, devicePixelRatio);
  }

  drawText(dimensionsPx, printParams, editableTextArea, editableTextData) {
    this.y = dimensionsPx.topOffset;
    this.x = dimensionsPx.leftOffset;
    this.ctx.clearRect(0, 0, this.image.clientWidth, this.image.clientHeight);
    this.drawCanvasText(dimensionsPx, printParams, editableTextArea, editableTextData);
  }

  findImageWrapperParent() {
    const width = parseFloat(this.image.clientWidth);
    const height = parseFloat(this.image.clientHeight);
    let found = false;
    let parent = this.image;
    let elementWidth = width;
    let elementHeight = height;
    let topOffset = 0;
    let leftOffset = 0;

    do {
      if ((elementWidth > width || elementHeight > height)
          && (parent.nodeName !== 'LI' && parent.nodeName !== 'UL')) {
        found = true;
      } else {
        if (parent) {
            parent = parent.parentElement;
            elementWidth = parseFloat(parent.clientWidth);
            elementHeight = parseFloat(parent.clientHeight);
            const style = window.getComputedStyle(parent);
            const marginTop = parseFloat(style.marginTop);
            const borderTopWidth = parseFloat(style.borderTopWidth);
            const paddingLeft = parseFloat(style.paddingLeft);
            const borderLeftWidth = parseFloat(style.borderLeftWidth);
            let paddingTop = parseFloat(style.paddingTop);
            let marginLeftCalc = style.marginLeft;
            if (Math.round(paddingTop) === Math.round(height)) {
                paddingTop = 0;
            }
            if (marginLeftCalc.includes('-')) {
                marginLeftCalc = 0;
            } else {
                marginLeftCalc = parseFloat(marginLeftCalc);
            }
            topOffset = topOffset + paddingTop + marginTop + borderTopWidth;
            leftOffset = leftOffset + paddingLeft + marginLeftCalc + borderLeftWidth;
        }
      }
    } while (found === false);

    // Adjust canvas position
    this.canvas.style.left = `${leftOffset}px`;
    this.canvas.style.top = `${topOffset}px`;

    return parent;
  }

  drawCanvasText(dimensionsPx, printParams, editableTextArea, editableTextData) {
    this.ctx.fillStyle = printParams.color;
    const txt = printParams.text;

    let fontSize = 50;
    if (editableTextArea.fontSize != null && parseInt(editableTextArea.fontSize, 20) !== 0) {
      fontSize = parseInt(editableTextArea.fontSize, 20);
    }

    const fontFamily = printParams.font;
    const fontUnit = 'px';

    // Merchr Designer Tool uses a bounding box with padding,
    // This is around 6 pixels so adjustment made below,
    // being 2px for top 4px for bottom
    const rectHeight = dimensionsPx.height;
    let textWidth = this.ctx.measureText(txt).width;
    let textHeight = this.ctx.measureText(txt).actualBoundingBoxAscent
      + this.ctx.measureText(txt).actualBoundingBoxDescent;

    do {
      fontSize--;
      this.ctx.font = `${fontSize}${fontUnit} ${fontFamily}`;
      textWidth = this.ctx.measureText(txt).width;
      textHeight = this.ctx.measureText(txt).actualBoundingBoxAscent
      + this.ctx.measureText(txt).actualBoundingBoxDescent;
    } while (textWidth > dimensionsPx.width || textHeight > rectHeight);

    let textAlign = 'center';
    if (editableTextData.textAlign != null) {
      if (editableTextData.textAlign === 'left') {
        textAlign = 'start';
      }
      if (editableTextData.textAlign === 'right') {
        textAlign = 'end';
      }
    }

    printAt(this.ctx, txt, this.x, this.y, dimensionsPx.width, textAlign);

    // eslint-disable-next-line no-shadow
    function printAt(context, text, x, y, areaWidth, textAlign) {
      let printX = parseFloat(x);
      if (textAlign === 'center') {
        // Center gets calculated at the x position
        const half = parseFloat(areaWidth / 2);
        printX = parseFloat(x) + half;
      }
      context.textAlign = textAlign;
      context.textBaseline = 'top';
      context.fillText(text, printX, y);
    }
  }
}
