<template>
    <div class="billing-details">
        <h2 class="order__title title--03">Billing Details</h2>
        <div class="billing-details__section">
            <div class="billing-details__item">
                <InputField
                    v-model="billing_details_data.first_name"
                    name="billing first name"
                    label="First name"
                    placeholder="Annie"
                    :is_required="true"
                    error_text="Field is required"
                    :show_error="v$.billing_details_data.first_name.$touch && v$.billing_details_data.first_name.$error"
                    @onChange="(value) => onBillingFieldChange({ value, field: 'first_name' })"
                />
            </div>
            <div class="billing-details__item">
                <InputField
                    name="billing last name"
                    v-model="billing_details_data.last_name"
                    :show_error="v$.billing_details_data.last_name.$touch && v$.billing_details_data.last_name.$error"
                    label="Last name"
                    placeholder="Smith"
                    :is_required="true"
                    error_text="Field is required"
                    @onChange="(value) => onBillingFieldChange({ value, field: 'last_name' })"
                />
            </div>
            <div class="billing-details__item billing-details__item--wide">
                <InputField
                    name="billing company name"
                    v-model="billing_details_data.company"
                    label="Company name (optional)"
                    error_text=""
                    @onChange="(value) => onBillingFieldChange({ value, field: 'company' })"
                />
            </div>
            <div class="billing-details__item billing-details__item--wide">
                <InputField
                    v-model="billing_details_data.billing_street"
                    v-model:extra_field_value="billing_details_data.billing_apartment"
                    label="Street address"
                    placeholder="House number or street name"
                    :extra_field="true"
                    :is_required="true"
                    extra_field_placeholder="Apartment, suite, unit, etc. (optional)"
                    @onChange="(value) => onBillingFieldChange({ value, field: 'billing_street' })"
                    @onExtraChange="(value) => onBillingFieldChange({ value, field: 'billing_apartment' })"
                    :show_error="v$.billing_details_data.billing_street.$touch && v$.billing_details_data.billing_street.$error"
                />
            </div>
            <div class="billing-details__item">
                <InputField
                    name="billing country"
                    v-model="billing_details_data.billing_country"
                    label="Country/Region"
                    :is_required="true"
                    :show_error="v$.billing_details_data.billing_country.$touch && v$.billing_details_data.billing_country.$error"
                    error_text="Field is required"
                    @onChange="(value) => onBillingFieldChange({ value, field: 'billing_country' })"
                />
            </div>
            <div class="billing-details__item">
                <InputField
                    name="billing county"
                    v-model="billing_details_data.billing_state"
                    label="County (optional)"
                    @onChange="(value) => onBillingFieldChange({ value, field: 'billing_state' })"
                />
            </div>
            <div class="billing-details__item">
                <InputField
                    name="billing city"
                    v-model="billing_details_data.billing_city"
                    label="Town/City"
                    :is_required="true"
                    :show_error="v$.billing_details_data.billing_city.$touch && v$.billing_details_data.billing_city.$error"
                    error_text="Field is required"
                    @onChange="(value) => onBillingFieldChange({ value, field: 'billing_city' })"
                />
            </div>
            <div class="billing-details__item">
                <InputField
                    name="billing post code"
                    v-model="billing_details_data.billing_zip_code"
                    label="Postcode"
                    :is_required="true"
                    :show_error="v$.billing_details_data.billing_zip_code.$touch && v$.billing_details_data.billing_zip_code.$error"
                    error_text="Field is required"
                    @onChange="(value) => onBillingFieldChange({ value, field: 'billing_zip_code' })"
                />
            </div>
            <div class="billing-details__item">
                <InputField
                    type="text"
                    name="billing phone"
                    v-model="billing_details_data.phone"
                    :show_error="v$.billing_details_data.phone.phoneValidation.$invalid && v$.billing_details_data.phone.$dirty"
                    label="Phone (optional)"
                    error_text="Enter a valid phone number"
                    @onChange="(value) => onBillingFieldChange({ value, field: 'phone' })"
                />
            </div>
            <div class="billing-details__item">
                <InputField
                    name="billing email"
                    v-model="billing_details_data.email"
                    label="Email address"
                    :is_required="true"
                    :show_error="v$.billing_details_data.email.$error && v$.billing_details_data.email.$dirty"
                    :error_text="v$.billing_details_data.email.emailValidation.$invalid ? 'Enter a valid email' : 'Field is required'"
                    @onChange="(value) => onBillingFieldChange({ value, field: 'email' })"
                />
            </div>
        </div>
        <div class="billing-details__check-section">
            <Checkbox :state="Boolean(is_deliver_to_different_address)" @onChange="onCheckboxChange" />
            <span class="billing-details__check-text">Deliver to a different address?</span>
        </div>
        <div class="billing-details__section">
            <template v-if="is_deliver_to_different_address">
                <div class="billing-details__section">
                    <div class="billing-details__item">
                        <InputField
                            name="shipping first name"
                            v-model="shipping_details_data.shipping_first_name"
                            label="First name"
                            placeholder="Annie"
                            :is_required="true"
                            :show_error="v$.shipping_details_data.shipping_first_name.$error"
                            error_text="Field is required"
                            @onChange="(value) => onShippingFieldChange({ value, field: 'shipping_first_name' })"
                        />
                    </div>
                    <div class="billing-details__item">
                        <InputField
                            name="shipping last name"
                            v-model="shipping_details_data.shipping_last_name"
                            label="Last name"
                            placeholder="Smith"
                            :is_required="true"
                            :show_error="v$.shipping_details_data.shipping_last_name.$error"
                            error_text="Field is required"
                            @onChange="(value) => onShippingFieldChange({ value, field: 'shipping_last_name' })"
                        />
                    </div>
                    <div class="billing-details__item billing-details__item--wide">
                        <InputField
                            v-model="shipping_details_data.shipping_street"
                            v-model:extra_field_value="shipping_details_data.shipping_apartment"
                            label="Street address"
                            placeholder="House number or street name"
                            :extra_field="true"
                            :is_required="true"
                            :show_error="v$.shipping_details_data.shipping_street.$touch && v$.shipping_details_data.shipping_street.$error"
                            extra_field_placeholder="Apartment, suite, unit, etc. (optional)"
                            @onChange="(value) => onShippingFieldChange({ value, field: 'shipping_street' })"
                            @onExtraChange="(value) => onShippingFieldChange({ value, field: 'shipping_apartment' })"
                        />
                    </div>
                    <div class="billing-details__item">
                        <InputField
                            name="shipping country"
                            v-model="shipping_details_data.shipping_country"
                            label="Country/Region"
                            :is_required="true"
                            :show_error="v$.shipping_details_data.shipping_country.$touch && v$.shipping_details_data.shipping_country.$error"
                            error_text="Field is required"
                            @onChange="(value) => onShippingFieldChange({ value, field: 'shipping_country' })"
                        />
                    </div>
                    <div class="billing-details__item">
                        <InputField
                            name="shipping county"
                            v-model="shipping_details_data.shipping_state"
                            label="County (optional)"
                            error_text=""
                            @onChange="(value) => onShippingFieldChange({ value, field: 'shipping_state' })"
                        />
                    </div>
                    <div class="billing-details__item">
                        <InputField
                            name="shipping city"
                            v-model="shipping_details_data.shipping_city"
                            label="Town/City"
                            :is_required="true"
                            :show_error="v$.shipping_details_data.shipping_city.$touch && v$.shipping_details_data.shipping_city.$error"
                            error_text="Field is required"
                            @onChange="(value) => onShippingFieldChange({ value, field: 'shipping_city' })"
                        />
                    </div>
                    <div class="billing-details__item">
                        <InputField
                            name="shipping post code"
                            v-model="shipping_details_data.shipping_zip_code"
                            label="Postcode"
                            :is_required="true"
                            :show_error="v$.shipping_details_data.shipping_zip_code.$touch && v$.shipping_details_data.shipping_zip_code.$error"
                            error_text="Field is required"
                            @onChange="(value) => onShippingFieldChange({ value, field: 'shipping_zip_code' })"
                        />
                    </div>
                </div>
            </template>
            <div class="billing-details__item billing-details__item--wide">
                <InputField
                    name="customer note"
                    :is_textarea="true"
                    v-model="customer_note"
                    label="Order notes (optional)"
                    placeholder="Notes about your order, eg. special notes for delivery. "
                    @onChange="(value) => $emit('onChangeCustomerNote', value)"
                />
            </div>
        </div>
    </div>
</template>
<script src="./billingDetails.js" />
<style src="./billingDetails.scss" lang="scss" scoped />
