import CountPicker from "@/components/shared/countPicker/countPicker.vue";
import EmptyState from "@/components/shared/emptyState/emptyState.vue";
import ProductCardHorizontal from "@/components/shared/productCardHorizontal/productCardHorizontal.vue";
import CustomButton from "@/components/ui/customButton/customButton.vue";
import parseCost from "@/helpers/parseCost.js";
import round from "@/helpers/round_number.js";

import { mapActions, mapGetters } from "vuex";
import shipping_exchange_map from "../../helpers/shipping_exchange_map";

export default {
    name: "CartView",
    components: {
        ProductCardHorizontal,
        CustomButton,
        CountPicker,
        EmptyState,
    },
    data: () => {
        return {
            is_standard_shipping: true,
        };
    },
    methods: {
        ...mapActions("products", ["fetchAvailableProducts", "updateSubtotalPrice", "updateTotalPrice"]),
        standardShipping() {
            this.is_standard_shipping = true;
        },
        calculateShipping() {
            this.is_standard_shipping = false;
        },
        checkout() {

        },
        handleClick() {
            if (this.$route.path !== "/shop") {
                this.$router.push("/shop");
            }
        },
        parseCost,
        round,
    },
    async mounted() {
        await this.fetchAvailableProducts();
    },
    computed: {
        shipping_exchange_map() {
            return shipping_exchange_map;
        },
        ...mapGetters("products", {
            items_in_cart: "getProductInCart",
            available_products: "getAvailableProducts",
            unavailable_products: "getUnavailableProducts",
            shipping: "getShipping",
            subtotal: "getAvailableProductsTotalPrice",
            available_products_count: "getAvailableProductsTotalCount",
            unavailable_products_count: "getUnavailableProductsTotalCount",
        }),
        ...mapGetters("storeProps", { store_settings: "getStoreSettings" }),
    },
};
