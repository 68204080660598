<template>
    <div class="container container--small">
        <div class="account">
            <template v-if="!order_data">
                <div class="title title--01">Check your order</div>
                <OrderForm @onSubmit="getOrderData" @onInput="onInput" :error="error" />
            </template>
            <OrderDetails :order_data="order_data" v-if="order_data" @onClear="clear" />
        </div>
    </div>
</template>
<script src="./AccountView.js" />
<style src="./AccountView.scss" lang="scss" scoped />
