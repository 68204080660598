export default {
    props: {
        text: {
            type: String,
            defaultValue: "",
        },
        is_disabled: {
            type: Boolean,
            defaultValue: false,
        },
    },
    emits: {
        onClick: null,
    },
    methods: {
        handleClick() {
            this.$emit("onClick");
        },
    },
};
