<template>
    <div class="checkout container container--small">
        <Preloader :isGlobal="true" :show="is_processing" :is_global="true"/>
        <OrderStatus
            v-show="is_status_popup"
            :status="order_status"
            :success_data="response_data"
            :heading="request_error?.heading || 'Your Payment has been processed successfully'"
            :button_text="request_error?.button_text || 'Back to shop'"
            :description="request_error?.description || 'You can check your order information'"
            @onClick="handlePopupAction"
        />
        <EmptyState v-if="!available_products.length && !is_initial_loading && !is_status_popup" icon="emptyCart" text="No products in checkout" text_button="Back to shop" @onClick="handleClick" />
        <div v-else class="checkout__holder">
            <div class="checkout__billing-col">
                <BillingDetails
                    @onBillingDetailsChange="onBillingDetailsChange"
                    @onShippingDetailsChange="onShippingFieldChange"
                    @onCheckboxChange="onDifferentAddressChange"
                    @onChangeCustomerNote="onChangeCustomerNote"
                    @validationCallback="validationCallback"
                    :customer_note="customer_note"
                    :billing_details="billing_details"
                    :shipping_details="shipping_details"
                    :is_deliver_to_different_address="is_deliver_to_different_address"
                />
            </div>
            <div class="checkout__order-col">
                <OrdersList />
                <Summary ref="summary" />
                <div class="checkout__submit-btn">
                    <div class="checkout__submit-error" v-if="submit_error">
                        <span>Please fill the required fields before continuing.</span>
                    </div>
                    <CustomButton text="Buy now" @onClick="submitPayment" />
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./CheckoutView.js" />
<style src="./CheckoutView.scss" lang="scss" scoped />
