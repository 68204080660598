<template>
    <div class="page">
        <Header :theme="themeType" />
        <main v-if="!is_loading" class="page__main">
            <Banner v-if="!hideBanner()" />
            <router-view />
        </main>
        <Footer :theme="themeType" />
        <Preloader v-if="!is_preview_mode" :show="is_props_loading" :is_global="true" />
    </div>
</template>

<script src="./common.js"></script>
