import Banner from "@/components/shared/banner/banner.vue";
import ContactUsForm from "@/components/shared/contactUsForm/contactUsForm.vue";

export default {
    name: "Delivery",
    components: {
        Banner,
        ContactUsForm,
    },
    data: () => {
        return {};
    },
    methods: {},
};
