import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import ProductCard from "@/components/shared/productCard/productCard.vue";
import { Autoplay } from "swiper/modules";
import { mapActions, mapGetters } from "vuex";
import CartService from "@/services/cart.service";

const cartService = new CartService();

export default {
    name: "Carousel",
    components: {
        Swiper,
        SwiperSlide,
        ProductCard,
    },
    props: {
        heading: {
            type: String,
            defaultValue: "",
        },
    },
    data: () => ({
        items: [],
        store_products_available: [],
        store_products_unavailable: [],
    }),
    methods: {
        ...mapActions("products", [
            "setProductToFavorite",
            "fetchAvailableProducts",
            "removeProductFromFavorite",
            "fetchFavoriteProducts",
            "setProductToCart",
            "updateProductInCart",
            "updateFavoriteProducts",
        ]),
        async onAddToCart(prod) {
            const { product, count, is_available } = this.products_in_cart_hash[prod.id] || {};

            if (this.products_in_cart_hash[prod.id]) {
                await this.updateProductInCart({ product, count: count + 1, is_available });
            } else {
                await this.setProductToCart({ product: prod });
            }

            this.$notify({
                title: "Product was added to cart!",
                type: "success",
            });
        },
        async onRemove(product) {
            await this.removeProductFromFavorite(product.id);
        },
        Autoplay,
        async handleFavoriteClick(id) {
            await this.removeProductFromFavorite(id);
        },
        onLinkClick() {
            this.$emit("onLinkClick");
        },
        async checkAllItems() {
            const store_products_available = this.store_products_available;
            const store_products_unavailable = this.store_products_unavailable;

            const updated_list_with_favorite_products = this.favoriteProducts.reduce((acc, current) => {
                const the_same_product_from_response = store_products_available.find(sp => sp.id === current.id);
                const not_available_store_product = store_products_unavailable.find(sp => sp.id === current.id);

                if (the_same_product_from_response) {
                    acc.push({
                        ...current,
                        ...the_same_product_from_response,
                        store_product_image_path: the_same_product_from_response.store_product_image_url,
                        product_name: the_same_product_from_response.store_product_name,
                        is_available: true,
                    });
                } else if (not_available_store_product?.store_product_image_url) {
                    acc.push({ ...current, store_product_image_path: not_available_store_product?.store_product_image_url, is_available: false });
                } else if (!not_available_store_product) {
                    const current_modified = current.store_product_image_url ? { ...current, store_product_image_path: current.store_product_image_url } : current;
                    acc.push({ ...current_modified, is_available: true });
                }
                return acc;
            }, []);

            this.items = updated_list_with_favorite_products;
        },
    },
    emits: {
        onAddToCart: () => null,
    },
    computed: {
        ...mapGetters("products", {
            favoriteProducts: "getFavoriteProducts",
            products_in_cart_hash: "getProductsInCartHash",
        }),
        ...mapGetters("storeProps", {
            storeSettings: "getStoreSettings",
        }),
    },
    watch: {
        favoriteProducts() {
            this.checkAllItems();
        },
    },
    async mounted() {
        await this.fetchFavoriteProducts();
        const parsed_favorite_product_list = this.favoriteProducts.map(({ id, combination, store_product_variant_id }) => ({
            id,
            combination: combination || null,
            store_product_variant_id: store_product_variant_id || null,
        }));
        const {
            data: { store_products_available, store_products_unavailable },
        } = await cartService.postProducts({ store_products: parsed_favorite_product_list });

        this.store_products_available = store_products_available;
        this.store_products_unavailable = store_products_unavailable;
        await this.checkAllItems();
        await this.updateFavoriteProducts(this.items);

        this.is_loading = false;
    },
};
