import Icon from "@/components/ui/icon/icon.vue";

export default {
    name: "colorPicker",
    components: { Icon },
    props: {
        colors: {
            type: Array,
            defaultValue: [],
        },
        heading: {
            type: String,
            defaultValue: "",
        },
        selected: {
            type: String,
            defaultValue: "",
        },
        showColorSelector: {
            defaultValue: false,
            type: Boolean
        }
    },
    emits: {
        onClick: null,
        onColorPickerChange: null
    },
    methods: {
        handleClick(color) {
            this.$emit("onClick", color);
        },
        handleChangedColor(val) {
            this.$emit("onColorPickerChange", val.target.value);
        },
        handleInputColor(val) {
            this.handleClick(val.target.value)
        }
    },
};
